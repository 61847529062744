import { html } from "htm/preact";
import { useState, useEffect, useRef } from "preact/hooks";
import FullCalendar, { DateSelectArg, EventDropArg } from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';

import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Datetime from 'react-datetime';
import { generateId, useInput } from "./core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import * as bootstrap from "bootstrap";
import { searchPatients, useSession } from "./store";

function showEventInfo() {
    var m = new bootstrap.Modal(document.getElementById('event-info') as any)
    m.show();
}

function hideEventInfo() {
    var m = new bootstrap.Modal(document.getElementById('event-info') as any)
    m.hide();
}


export function EventInfo(){
    return html`<div class="modal fade" id="eventDetailsModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border"></div>
      </div>
    </div>`;
}

function CalendarEventModal({ isOpenModal, setIsOpenModal, modalEventContent }:any) {
    const toggle = () => setIsOpenModal(!isOpenModal);
  
    const { title, end, start } = isOpenModal && modalEventContent.event;
    const { description, location, organizer, schedules } = isOpenModal && modalEventContent.event.extendedProps;

 /* class="modal fade" id="event-info" tabindex="-1"*/
    return html`<div>
    <div class="modal-dialog">
        <div class="modal-content border">
            <form onSubmit=${(e:any) => {
          e.preventDefault();
          if ((e.target as any).checkValidity()) {

         // setIsOpenScheduleModal(false);
        //  setInitialEvents([...initialEvents, formObj]);
         // (document.querySelector('[data-dismiss="modal"]') as any).click();
          }
        }} autocomplete="off">
                <div class="modal-header px-card bg-light border-bottom-0">
            <div class="modal-title"><h5 class="mb-0">Meeting</h5></div>
            <button class="close font-weight-normal">×</button></div>
            <div class="px-card pb-card pt-1 fs--1 modal-body"><div class="mt-3 media">
                <span class="fa-stack ml-n1 mr-3">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" class="svg-inline--fa fa-circle fa-w-16 text-200 fa-stack-2x" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="align-left" class="svg-inline--fa fa-align-left fa-w-14 fa-inverse text-primary fa-stack-1x" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M12.83 352h262.34A12.82 12.82 0 0 0 288 339.17v-38.34A12.82 12.82 0 0 0 275.17 288H12.83A12.82 12.82 0 0 0 0 300.83v38.34A12.82 12.82 0 0 0 12.83 352zm0-256h262.34A12.82 12.82 0 0 0 288 83.17V44.83A12.82 12.82 0 0 0 275.17 32H12.83A12.82 12.82 0 0 0 0 44.83v38.34A12.82 12.82 0 0 0 12.83 96zM432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path></svg>
                </span><div class="media-body">
                    <h6>Description</h6>
                    <p class="mb-0 text-justify">Sample example of a event with url. Click the event, will redirect to the given link.</p>
                </div>
            </div>
            <div class="mt-3 media">
                <span class="fa-stack ml-n1 mr-3">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" class="svg-inline--fa fa-circle fa-w-16 text-200 fa-stack-2x" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-check" class="svg-inline--fa fa-calendar-check fa-w-14 fa-inverse text-primary fa-stack-1x" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z"></path></svg>
                </span>
                <div class="media-body">
                    <h6>Time and Date</h6>
                    <span>Thursday, November 26, 2020 12:00 AM</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end bg-light px-card border-top-0 modal-footer">
                        
                        <a href="pages/event-create" class="btn btn-falcon-default btn-sm">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil-alt" class="svg-inline--fa fa-pencil-alt fa-w-16 fs--2 mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"></path></svg>
                        <span>Edit</span>
                    </a>
                    <a href="pages/event-detail" class="btn btn-falcon-primary btn-sm">
                    <span>See more details</span>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8 fs--2 ml-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
                        </a>
                    </div>
                </div>
                    </div>
                        </div>`;
}


export interface CalendarEvent {
    id: string;
    title:string;
    start: any;
    description: string;
    className: 'bg-soft-primary' | 'bg-soft-success' | 'bg-soft-danger';
}
export function Calendar() {

    const views = [
        { label: 'Vue Mensuelle', value: 'dayGridMonth' },
        { label: 'Vue Hebdomadaire', value: 'timeGridWeek' },
        { label: 'Vue Quotidienne', value: 'timeGridDay' },
        { label: 'Liste', value: 'listWeek' },
        { label: 'Vue de l\'année', value: 'listYear' },
    ];

    const [state,dispatch] = useSession();
    const [show,setShow] = useState(false);

    const events = state.visits.filter(it=>it.scheduledAt && !it.deletedAt).map(it=>({
        id:it._id,
        title: it.name,
        patientId: it.patientId,
        start: new Date(it.scheduledAt || 0).toISOString(),
        end: new Date((it.scheduledAt || 0)  + ( 1000 * 60 * 10)).toISOString(),
        className: it.cameAt ? 'bg-soft-success' : 'bg-soft-primary',
        editable : !it.cameAt,
    }));

    const calendarRef = useRef();
    const [calendarApi, setCalendarApi] = useState({} as any);
    const [title, setTitle] = useState('');
    const [currentView, setCurrentView] = useState(views[1]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
    const [modalEventContent, setModalEventContent] = useState(false);
    const [addScheduleStartDate, setAddScheduleStartDate] = useState(null);

    useEffect(() => {
        const handler = () => {
            setShow(false);
        };
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    },[]);

    const buttonText = {
        today: 'Aujourd\'hui',
        month: 'Vue mensuelle',
        week: 'semaine',
        day: 'jour',
        listWeek: 'vue liste',
        listYear: 'année'
    };

    const eventTimeFormat = {};/* {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: true
    };*/


    const viewsOptions = {
        week: {
            eventLimit: 3333
        }
    };

    useEffect(() => {
        setCalendarApi((calendarRef.current as any).getApi());
        dispatch({type:'calendarRef', data: calendarRef.current });
    }, []);



    const setView = (view: any) => {
        setCurrentView(view);
        setShow(false);
        calendarApi.changeView(view.value);
        setTitle(calendarApi.getCurrentData().viewTitle);
    };

    const handleEventClick = (info: any) => {
        return;
        if (info.event.url) {
            window.open(info.event.url);
            info.jsEvent.preventDefault();
        } else {
            setModalEventContent(info);
            showEventInfo();
            setIsOpenModal(true);
        }
    };


    return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row gx-0 align-items-center">
            <div class="col-auto d-flex justify-content-end order-md-1">
            
                <button onclick=${()=>{
                  calendarApi.prev();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }} class="btn icon-item icon-item-sm shadow-none p-0 mr-1 ml-md-2" type="button" data-event="prev"
                    data-toggle="tooltip" title="Previous">
                    <${FontAwesomeIcon} icon=${['fas', 'arrow-left' ]}/>
                </button>
                <button onclick=${()=>{
                  calendarApi.next();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }} class="btn icon-item icon-item-sm shadow-none p-0 mr-1 mr-lg-2" type="button" data-event="next"
                    data-toggle="tooltip" title="Next">
                    <${FontAwesomeIcon} icon=${['fas', 'arrow-right' ]}/>
                </button>
            </div>
            <div class="col-auto col-md-auto order-md-2">
                <h6 class="mb-0 fs-0 fs-sm-1 fs-lg-2 calendar-title">
                    ${title || calendarApi.currentDataManager?.data?.viewTitle}
                </h6>
            </div>
            <div class="col col-md-auto d-flex justify-content-end order-md-3">
                <button onclick=${()=>{
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }} class="btn btn-falcon-primary btn-sm" type="button" data-event="today">Today</button>
            </div>
            <div class="col-md-auto d-md-none">
                <hr />
            </div>
<!--
            <div class="col-auto d-flex order-md-0">
                <button class="btn btn-primary btn-sm" type="button" data-toggle="modal" data-target="#addEventModal">
                    <span class="fas fa-plus mr-2"></span>Add Schedule</button>
            </div>
-->
            <div class="col d-flex justify-content-end order-md-2">
                <div class="dropdown font-sans-serif mr-md-2">
                    <button onclick=${(ev:Event)=>{
                        ev.preventDefault();
                        ev.stopImmediatePropagation();
                        ev.stopPropagation();
                        setShow(!show);
                    }} class="btn btn-falcon-default text-600 btn-sm dropdown-toggle dropdown-caret-none"
                        type="button" id="email-filter" data-toggle="dropdown" data-boundary="viewport"
                        aria-haspopup="true" aria-expanded="false">
                        <span data-view-title="data-view-title" style="min-width:150px">${currentView.label} </span>
                        <${FontAwesomeIcon} icon=${['fas', 'sort' ]} class="ml-2 fs--1" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-right border py-2 ${show?'show':''}" aria-labelledby="email-filter">
                        ${views.map(view => html`<a onclick=${()=> setView(view)}
                            class="${currentView.value == view.value ? 'active' : ''} dropdown-item d-flex
                            justify-content-between">
                            ${view.label}<!--<span class="icon-check">
                                <span class="fas fa-check" data-fa-transform="down-4 shrink-4"></span>
                            </span>--></a>`)}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <${FullCalendar} ref=${calendarRef} headerToolbar=${false} plugins=${[dayGridPlugin, listPlugin,
            bootstrapPlugin, timeGridPlugin, interactionPlugin, listPlugin]} initialView=${currentView.value}
            themeSystem="bootstrap" dayMaxEvents=${2} height=${window.innerHeight-180} stickyHeaderDates=${false} editable selectable
            selectMirror unselectAuto=${false} select=${(info:     any) => {
           // setIsOpenScheduleModal(true);
/*
            var m = new bootstrap.Modal(document.getElementById('addEventModal') as any)
            m.show();
*/
            dispatch({type:'calendar-select',data:info});
            (document.querySelector('.search-input') as any)?.focus();
            if(info.start) {setAddScheduleStartDate(info.start);}
        }}
            views=${viewsOptions}
            eventTimeFormat=${eventTimeFormat}
            eventClick=${handleEventClick}
            events=${events}
            buttonText=${buttonText}
            slotMinTime="09:00:00"
            slotMaxTime="17:00:00"
            allDaySlot=${false}
            slotDuration="00:15:00"
            slotEventOverlap=${false}
            slotLabelInterval=${{minutes:15}}
            agendaEventMinHeight=${10}
            eventDrop=${(ev: EventDropArg)=> dispatch({
                type:'mutate',collection:'visits',id:ev.event.id,
                data:{scheduledAt:new Date(ev.event.start || 0).getTime() }
            }) }
            eventContent=${function(arg:any,createElement:any){ return  [createElement('a',
             {href:'/patients/'+ arg.event.extendedProps.patientId },
             arg.event.title),
             createElement('a',{onclick:()=>{
                dispatch({type:'mutate',collection:'visits',id: arg.event.id, data: {deletedAt:Date.now()}})
             }, style:'float:right;padding-right:4px' },'x')
             ] }}

            />
    </div>
</div>`;
}

export function AddSchedule({
    setIsOpenScheduleModal,
    isOpenScheduleModal,
    setInitialEvents,
    initialEvents,
    addScheduleStartDate,
    setAddScheduleStartDate
  }:any){

    const [state,dispatch] = useSession();
    const [show,setShow] = useState(false);

    const [search,setSearch] = useInput('');
console.log(search);
    const patients = search.length > 1 ? searchPatients(state.patients,search) : [];


   const toggle = () => setIsOpenScheduleModal(!isOpenScheduleModal);

    const [formObj, setFormObj] = useState({ id: generateId(state.user?.organizationId || '') });
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
/*    const closeBtn = (
      <button className="close font-weight-normal" onClick={toggle}>
        &times;
      </button>
    );
  */
    const handleChange = (target:any) => {
      let name = target.name;
      let value = name === 'allDay' ? target.checked : target.value;
      const event = { ...formObj, [name]: value };
//      Store.add(['Event',event])
      setFormObj(event);
    };
    
    useEffect(() => {
      !isOpenScheduleModal && setAddScheduleStartDate(null);
      !isOpenScheduleModal && setEndDate(null);
      !isOpenScheduleModal && setStartDate(null);
      setFormObj({ ...formObj, start: addScheduleStartDate } as any);
      // eslint-disable-next-line
    }, [isOpenScheduleModal, addScheduleStartDate]);

    
    return html`<div class="modal fade ${isOpenScheduleModal ? 'show':''}" 
            style="opacity:${isOpenScheduleModal?1:0}"

        style="display:${isOpenScheduleModal?'block':'none'};background-color:rgba(0,0,0,0.5)" >
    <div class="modal-dialog">
        <div class="modal-content border">
            <form onSubmit=${(e:any) => {
          e.preventDefault();
          if ((e.target as any).checkValidity()) {

          setIsOpenScheduleModal(false);
          setInitialEvents([...initialEvents, formObj]);
          (document.querySelector('[data-dismiss="modal"]') as any).click();
          }
        }} id="addEventForm" autocomplete="off">
                <div class="modal-header px-card bg-light border-bottom-0">
                    <h5 class="modal-title">Create Schedule</h5>
                    <button onclick=${()=>{
                        setIsOpenScheduleModal(false);
            
                    }} class="btn-close mr-n1" type="button" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-card">
                    <div class="mb-3">
                        <label class="fs-0" for="eventTitle">Title</label>
                        <input list="patients" class="form-control" id="eventTitle" type="text" name="title" oninput=${setSearch} required />
<datalist id="patients">
  ${patients.map(it=>html`<option name=${it.firstName} value=${it.firstName+' '+it.lastName}>${it.firstName} </option>`)}
</datalist>
                    
                    </div>
                    <div class="mb-3">
                        <label class="fs-0" for="eventStartDate">Start Date</label>
                        <${Datetime}
                            timeFormat=${true}
                            value=${startDate || addScheduleStartDate}
                            onChange=${(dateTime:any) => {
                                if (dateTime._isValid) {
                                setStartDate(dateTime);
                                let date:any = {};
                                date.value = dateTime.format();
                                date.name = 'start';
                                handleChange(date);
                                }
                            }}
                            dateFormat="MM-DD-YYYY"
                            inputProps=${{ placeholder: 'MM-DD-YYYY H:M', id: 'eventStart' }}
                            />
                    </div>
                    <div class="mb-3">
                        <label class="fs-0" for="eventEndDate">End Date</label>
                        <${Datetime}
                            value=${endDate}
                            timeFormat=${true}
                            onChange=${(dateTime:any) => {
                                if (dateTime._isValid) {
                                setEndDate(dateTime);
                                let date:any = {};
                                date.value = dateTime.format();
                                date.name = 'end';
                                handleChange(date);
                                }
                            }}
                            dateFormat="MM-DD-YYYY"
                            inputProps=${{ placeholder: 'MM-DD-YYYY H:M', id: 'eventEnd' }}
                            />
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="eventAllDay" name="allDay" onchange=${({ target }:any) => handleChange(target)} />
                        <label class="form-check-label" for="eventAllDay">All Day
                        </label>
                    </div>
                    <div class="mb-3">
                        <label class="fs-0">Schedule Meeting</label>
                        <div><a class="btn bg-soft-info text-left text-info" href="#!"><span
                                    class="fas fa-video mr-2"></span>Add video conference link</a></div>
                    </div>
                    <div class="mb-3">
                        <label class="fs-0" for="eventDescription">Description</label>
                        <textarea class="form-control" rows="3" name="description" id="eventDescription" onchange=${({ target }:any) => handleChange(target)}></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="fs-0" for="eventLabel">Label</label>
                        <select class="form-select" id="eventLabel" name="label" onchange=${({ target }:any) => handleChange(target)}>
                            <option value="" selected="selected">None</option>
                            <option value="primary">Business</option>
                            <option value="danger">Important</option>
                            <option value="success">Personal</option>
                            <option value="warning">Must Attend</option>
                        </select>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-end align-items-center bg-light"><a class="mr-3 text-600"
                        href="pages/event-create.html">More options</a>
                    <button class="btn btn-primary btn-lg px-4" type="submit" >Save</button>
                </div>
            </form>
        </div>
    </div>
</div>


`;
}