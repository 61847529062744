// import "preact/debug";
import 'regenerator-runtime/runtime'
import 'react-datetime/css/react-datetime.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { faHome, faPlus, faEllipsisH ,fas } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import 'bootstrap';
import 'react-toastify/dist/ReactToastify.css';

library.add(faPlus,faEllipsisH,fas);



import { h, render, Component, createContext } from 'preact';
import { html } from 'htm/preact';
import { UrlPattern } from './routing';
import { ForgotPassword, login, Logout, register, RegisterMsg } from "./auth";
import { useContext, useEffect, useReducer, useState } from "preact/hooks";
import { Main } from './dashboard';
import { AddPatient, PatientsOverview } from './patients';
import {  Session, SessionContext, StateProvider, useSession } from './store';
import { Mutation } from '../shared/core';
import { ToastContainer } from 'react-toastify';
import { Router } from 'preact-router';


type Setter = { type: 'setter', setter: (model: Model, value: any) => void, value: any }

type Msg = { type: 'Login', value: login.Msg }
    | { type: 'Register', value: RegisterMsg };


const f: Mutation = {} as any;
export class Model {
    login = new login.Model();
    logged?: Logged;
}

export type Role = 'regular' | 'manager' | 'admin';

export interface Trip {
    destination: string;
    startDate: number;
    endDate: number;
    comment: string;
}

export interface Logged {
    trips: Trip[];
}


// view.bind([])



// useEffect(() => {
//sync(state, dispatch);

function View() {


    // dispatch(['put', 'Patient', 'sqdqsd', { firstName: "hello" }])
    // }, [state]);

    /*
    		// Advanced is an optional query
		// <Search path="/search/:query/:advanced?" />
*/
/*

*/

console.log('view')

    return html`<${StateProvider}>
    	<${Router}>
		<${Main} default  />
        <${login.ViewSplit} path="/login" />
        <${register.ViewSplit} path="/register" />
        <${Logout} path="/logout"/>
        <${ForgotPassword} path="/forgot-password"/>
	</${Router}>
    
    <${ToastContainer}
position="bottom-right"
autoClose=${5000}
hideProgressBar=${false}
newestOnTop=${false}
closeOnClick
rtl=${false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
</${StateProvider}>`;
}



render(html`<${View} />`, document.body)
