import { IsEmail, IsMongoId, IsObject, MinLength } from "class-validator";
import { Mixin, decorate } from "ts-mixer";

export async function randomString(length = 9) {
    const array = new Uint8Array(length);
    await crypto.getRandomValues(array);
    return btoa(String.fromCharCode(...array));
}


export class Id {

    @decorate(IsMongoId())
    _id!: string;

    @decorate(IsObject())
    timestamps!: { [key: string]: number };
}

export class Offline {
    @decorate(IsObject())
    timestamps!: { [key: string]: number };
}

export class LoginForm {

    @decorate(IsEmail())
    email!: string;

    @decorate(MinLength(6))
    password!: string;
}


export class Person {

    @decorate(MinLength(4))
    firstName!: string;

    @decorate(MinLength(4))
    lastName!: string;


    birthdate?: string;
    gender?: 'male' | 'female';
}

export class RegisterForm {
    user!: UserRegisterForm;
    organization?: OrganizationForm;
}

export class UserRegisterForm extends Mixin(LoginForm, Person) {

}

export class LoginResponse {
    token!: string;
    user!: User;
}

export class OrganizationForm {
    name!: string;
}

export class User extends Mixin(Id, Person) {

    organizationId!: string;

    roles!: string[];

    @MinLength(4)
    firstName!: string;

    @MinLength(4)
    lastName!: string;

    verified?: boolean = false;

}

export class Contact {


    email?: string;

    phones?: string[];

    address?: string;

    city?: { label: string, value: string };

    country?: string;
}


export class Stock {

}

export class Product {

    name!: string;
    price!: number;
    sku!: string;

    stock!: { [key: string]: Stock };
}


export class NewPatient extends Mixin(Person, Contact) {
    notes?: string;
    insuranceId?: string;
    insurance?: { label: string, value: string };
}

export class Patient extends Mixin(Id, NewPatient) {
    no?: number;
}


export interface Mutation {
    collection: string;
    objectId: string;
    data: any;
    time: number;
    organizationId?: string;
}

export class NewVisit {
    patientId!: string;
    price!: number;
    received!: number;
}

export class Billable extends Mixin(Id) {
    patientId!: string;
    date!: string;
    description!: string;
    price!: number;
    received!: number;
}


export class Prescription extends Mixin(Id) {
    patientId!: string;
    date!: string;

    insurance?: string;
    insuranceId?: string;
    lines!: { drug: any, posology: any, note: any }[];

}

/*
export class Visit extends Mixin(Id, NewVisit) { }

export class VisitProcedure extends Mixin(Id) {
    visit_id!: string;
    sub_procedure_id: String,
    name: String,
    price: i32,         // indicatif
    tooth: Option<i32>, // after use ??
    created_at: NaiveDateTime,
    updated_at: NaiveDateTime,
}
*/



export function to32(view: Uint8Array): string {

    const CHARS = '0123456789ABCDEFGHJKLMNPRSTVWXYZ';

    const length = view.byteLength;

    var bits = 0
    var value = 0
    var output = ''

    for (var i = 0; i < length; i++) {
        value = (value << 8) | view[i]
        bits += 8

        while (bits >= 5) {
            output += CHARS[(value >>> (bits - 5)) & 31]
            bits -= 5
        }
    }

    if (bits > 0) {
        output += CHARS[(value << (5 - bits)) & 31]
    }


    return output
}

export class PurchaseOrderItem {
    _id!: string;
    ref!: string;
    form?: string;
    requestedQuantity!: number;
    deliveredQuantity?: number;
}

export class PurchaseOrder {
    _id!: string;
    no!: number;
    organizationId!: string;
    items: PurchaseOrderItem[] = [];
    createdBy?: string;
    receivedBy?: string;
    deletedAt: any;
    status: any;
    date: any;
    createdAt!: number;
    createdByName!: string;
}