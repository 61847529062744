import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { html } from "htm/preact";
import { useState, useEffect, useRef } from "preact/hooks";

import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { isToday, useSession, Visit } from "./store";


function Item({_id,patientId,index,name,note,scheduledAt,cameAt,queueNumber}:Visit & {index:number,queueNumber:number}) {

    const [state,dispatch] = useSession();
    const [show,setShow] = useState(false);


    function onDeleteClick(){
        dispatch({type:'mutate',collection:'visits',id:_id, data:{deletedAt:Date.now()}})
    }

    useEffect(() => {
        const handler = () => {
            setShow(false);
        };
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    },[])


    return html`<${Draggable}  key=${_id} draggableId=${_id} index=${index} >              
    ${(provided:any, snapshot:any) => (
    html`<div ref=${provided.innerRef} ...${provided.draggableProps} 
    ...${provided.dragHandleProps} class="kanban-item">
                            <div class="card kanban-item-card hover-actions-trigger">
                                <div class="card-body">
                                    <div class="position-relative">
                                        <div class="dropdown font-sans-serif">
                                            <button onclick=${(ev:any)=>{
                                                ev.preventDefault();
                                                ev.stopImmediatePropagation();
                                                setShow(true);
                                            
                                            }}
                                                class="btn btn-sm btn-falcon-default kanban-item-dropdown-btn hover-actions"
                                                type="button" data-boundary="viewport" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h' ]}/>

                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right py-0 ${show?'show':''}">
                                            <!--<a
                                                    class="dropdown-item" href="#!">Add Card</a><a
                                                    class="dropdown-item" href="#!">Edit</a><a
                                                    class="dropdown-item" href="#!">Copy link</a>
                                                <div class="dropdown-divider"></div>
                                        --><a onclick=${onDeleteClick} class="dropdown-item text-danger">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="/patients/${patientId}" class="mb-0 font-weight-medium font-sans-serif stretched-link"
                                        data-toggle="modal" data-target="#kanban-modal-1">
                                        <strong>${queueNumber} (${time(scheduledAt || cameAt)})</strong> - ${name}
                                            <br/> ${note}
                                        </a>
                                            
                                </div>
                            </div>
                        </div>` )}
                    </${Draggable}>`;
}


function Header({label,count}:{label:string,count:number}) {
    return html`<div class="kanban-column-header">
    <h5 class="fs-0 mb-0">${label} <span class="text-500">(${count})</span></h5>
     <!--
    <div class="dropdown font-sans-serif btn-reveal-trigger">
        <button class="btn btn-sm btn-reveal py-0 px-2" type="button" id="kanbanColumn0"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                class="fas fa-ellipsis-h"></span></button>
               
        <div class="dropdown-menu dropdown-menu-right py-0" aria-labelledby="kanbanColumn0"><a
                class="dropdown-item" href="#!">Add Card</a><a class="dropdown-item"
                href="#!">Edit</a><a class="dropdown-item" href="#!">Copy link</a>
            <div class="dropdown-divider"></div><a class="dropdown-item text-danger"
                href="#!">Remove</a>
        </div>

    </div>
    -->
</div>

`;
}



export function Kanban() {

    const [state,dispatch] = useSession();

    const todayVisits = state.visits.filter(it=> !it.deletedAt && isToday(new Date(it.cameAt || it.scheduledAt || 0)))

    const getCategory = (visit:Visit) => {
        if(visit.endedAt) {
            return 'done';
        }else if(visit.startedAt) {
            return 'doing';
        }else if(visit.cameAt) {
            return 'waiting';
        }else if(visit.scheduledAt) {
            return 'scheduled';
        }else {
            return 'none';
        }
    };
    
// fake data generator
const getItems = (count:number) =>
Array.from({ length: count }, (v, k) => k).map(k => ({
  id: `item-${k}`,
  content: `item ${k}`
}));

// a little function to help us with reordering the result
const reorder = (list:any[], startIndex:number, endIndex:number) => {
const result = Array.from(list);
const [removed] = result.splice(startIndex, 1);
result.splice(endIndex, 0, removed);

return result;
};

const grid = 8;

const getItemStyle = (isDragging:any, draggableStyle:any) => ({
// some basic styles to make the items look a bit nicer
userSelect: "none",
padding: grid * 2,
margin: `0 0 ${grid}px 0`,

// change background colour if dragging
background: isDragging ? "lightgreen" : "grey",

// styles we need to apply on draggables
...draggableStyle
});

const getListStyle = (isDraggingOver:boolean) => ({
background: isDraggingOver ? "lightblue" : "lightgrey",
padding: grid,
width: 250
});

function onDragEnd(result:DropResult) {
    if(result.destination) {
        const current = todayVisits.find(it=>it._id == result.draggableId) as any;
        const diff = current.index - result.destination.index;
        const data:any = {...current,index: result.destination.index - 0.1 * diff/ Math.abs(diff) };
        if(result.destination.droppableId == 'waiting') {
            data.startedAt = null;
            data.endedAt= null;
            data.cameAt = data.cameAt || Date.now();
            data.queueNumber = data.queueNumber || ((todayVisits.sort((a, b) => (b.queueNumber || 0) - (a.queueNumber || 0)).map(it => it.queueNumber)[0] || 0) + 1);
        }else if(result.destination.droppableId == 'scheduled') {
            data.startedAt = null;
            data.endedAt= null;
            data.cameAt = null;

            const today = new Date();
            today.setHours(10,0,0,0);
            data.scheduledAt = data.scheduledAt || today.getTime();
        }else if(result.destination.droppableId == 'doing') {
            data.startedAt = data.startedAt || Date.now();
            data.endedAt= null;
            data.cameAt = data.cameAt || Date.now();
        }else if(result.destination.droppableId == 'done') {
            data.startedAt = data.startedAt || Date.now();
            data.endedAt= Date.now();
            data.cameAt = data.cameAt || Date.now();
        }
        dispatch({
            type: 'mutate', collection: 'visits', id: result.draggableId,
            data,
        });
    }
    console.log('result',result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }
/*
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });
    **/
  }


    return html`<div class="content">
        <!--
        <div class="row gx-0 bg-100 rounded-lg px-card py-2 mt-2 mb-3">
            <div class="col d-flex align-items-center">
                <h5 class="mb-0">Falcon</h5>
                <button class="btn btn-sm btn-falcon-default ml-3"><span class="far fa-star"></span></button>
                <div class="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
                <ul class="nav avatar-group mb-0 d-none d-md-flex">
                    <li class="nav-item dropdown"></li>
                    <li class="nav-item dropdown"><a class="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#"
                            role="button" data-toggle="dropdown">
                            <div class="avatar avatar-l">
                                <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />

                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-md px-0 py-3">
                            <div class="d-flex align-items-center position-relative px-3">
                                <div class="avatar avatar-2xl mr-2">
                                    <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />

                                </div>
                                <div class="flex-1">
                                    <h6 class="mb-0"><a class="stretched-link text-900" href="pages/profile.html">Anna
                                            Karinina</a></h6>
                                    <p class="mb-0 fs--2">Member</p>
                                </div>
                            </div>
                            <hr class="my-2" /><a class="dropdown-item" href="#!">Member Rule</a><a
                                class="dropdown-item text-danger" href="#!">Remove Member</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown"><a class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                            href="#" role="button" data-toggle="dropdown">
                            <div class="avatar avatar-l">
                                <img class="rounded-circle" src="assets/img/team/2.jpg" alt="" />

                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-md px-0 py-3">
                            <div class="d-flex align-items-center position-relative px-3">
                                <div class="avatar avatar-2xl mr-2">
                                    <img class="rounded-circle" src="assets/img/team/2.jpg" alt="" />

                                </div>
                                <div class="flex-1">
                                    <h6 class="mb-0"><a class="stretched-link text-900" href="pages/profile.html">Antony
                                            Hopkins</a></h6>
                                    <p class="mb-0 fs--2">Member</p>
                                </div>
                            </div>
                            <hr class="my-2" /><a class="dropdown-item" href="#!">Member Rule</a><a
                                class="dropdown-item text-danger" href="#!">Remove Member</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown"><a class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                            href="#" role="button" data-toggle="dropdown">
                            <div class="avatar avatar-l">
                                <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />

                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-md px-0 py-3">
                            <div class="d-flex align-items-center position-relative px-3">
                                <div class="avatar avatar-2xl mr-2">
                                    <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />

                                </div>
                                <div class="flex-1">
                                    <h6 class="mb-0"><a class="stretched-link text-900" href="pages/profile.html">Rowan
                                            Atkinson</a></h6>
                                    <p class="mb-0 fs--2">Member</p>
                                </div>
                            </div>
                            <hr class="my-2" /><a class="dropdown-item" href="#!">Member Rule</a><a
                                class="dropdown-item text-danger" href="#!">Remove Member</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown"><a class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                            href="#" role="button" data-toggle="dropdown">
                            <div class="avatar avatar-l">
                                <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-md px-0 py-3">
                            <div class="d-flex align-items-center position-relative px-3">
                                <div class="avatar avatar-2xl mr-2">
                                    <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                </div>
                                <div class="flex-1">
                                    <h6 class="mb-0"><a class="stretched-link text-900" href="pages/profile.html">John
                                            Doe</a></h6>
                                    <p class="mb-0 fs--2">Member</p>
                                </div>
                            </div>
                            <hr class="my-2" /><a class="dropdown-item" href="#!">Member Rule</a><a
                                class="dropdown-item text-danger" href="#!">Remove Member</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown"><a class="nav-link dropdown-toggle dropdown-caret-none p-0 ml-n1"
                            href="#" role="button" data-toggle="dropdown">
                            <div class="avatar avatar-l">
                                <div class="avatar-name rounded-circle avatar-button"><span>2+</span></div>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-md">
                            <h6 class="dropdown-header py-0 px-3 mb-0">Board Members</h6>
                            <div class="dropdown-divider"></div>
                            <div class="d-flex px-3"><a class="mr-2" href="pages/profile.html" data-toggle="tooltip"
                                    title="Anna Karinina">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />

                                    </div>
                                </a><a class="mr-2" href="pages/profile.html" data-toggle="tooltip"
                                    title="Antony Hopkins">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle" src="assets/img/team/2.jpg" alt="" />

                                    </div>
                                </a><a class="mr-2" href="pages/profile.html" data-toggle="tooltip"
                                    title="Rowan Atkinson">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />

                                    </div>
                                </a><a class="mr-2" href="pages/profile.html" data-toggle="tooltip" title="John Doe">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                    </div>
                                </a><a class="mr-2" href="pages/profile.html" data-toggle="tooltip" title="Emily Rose">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle" src="assets/img/team/5.jpg" alt="" />

                                    </div>
                                </a><a href="pages/profile.html" data-toggle="tooltip" title="Marry Jane">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle" src="assets/img/team/6.jpg" alt="" />

                                    </div>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-md-flex"></div>
                <div class="position-relative">
                    <button class="btn btn-sm btn-falcon-default dropdown-toggle dropdown-caret-none" id="invitePeople"
                        type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                            class="fas fa-plus mr-2"></span>Invite</button>
                    <div class="dropdown-menu dropdown-menu-right pt-2 pb-0" aria-labelledby="invitePeople"
                        style="min-width: 300px;">
                        <h6 class="dropdown-header text-center py-2">Invite To Board</h6>
                        <div class="dropdown-divider mb-0"></div>
                        <div class="p-3">
                            <form>
                                <div class="border rounded fs--2 mb-3">
                                    <div class="d-flex flex-between-center border-bottom bg-200">
                                        <div class="px-2">Anyone with the link can join</div>
                                        <div class="border-left">
                                            <button
                                                class="btn btn-link btn-sm text-decoration-none hover-300 rounded-0 fs--2"
                                                id="dataCopy" type="button" data-copy="#invite-link"
                                                data-toggle="tooltip" data-trigger="manual" title="Copy to clipboard">
                                                <span class="far fa-copy mr-2"></span>Copy link</button>
                                        </div>
                                    </div>
                                    <input class="form-control bg-white border-0 fs--2 px-1" id="invite-link"
                                        type="text" readonly="readonly"
                                        value="https://prium.github.io/falcon/kanban/QhNCShh8TdxKx0kYN1oWzzKJDjOYUXhm9IJ035laUVdWMYsUN5" />
                                </div>
                                <input class="form-control form-control-sm" type="text"
                                    placeholder="Enter name or email" />
                                <button class="btn btn-primary btn-sm btn-block mt-2" type="button">Send
                                    Invitation</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto d-flex align-items-center">
                <button class="btn btn-sm btn-falcon-default mr-2 d-none d-md-block"><span
                        class="fas fa-plus mr-2"></span>Add Column</button>
                <div class="dropdown font-sans-serif">
                    <button class="btn btn-sm btn-falcon-default dropdown-toggle dropdown-caret-none" type="button"
                        id="kanbanMenu" data-toggle="dropdown" data-boundary="viewport" aria-haspopup="true"
                        aria-expanded="false"><span class="fas fa-ellipsis-h"></span></button>
                    <div class="dropdown-menu dropdown-menu-right border py-2" aria-labelledby="kanbanMenu"><a
                            class="dropdown-item" href="#!">Copy link</a>
                        <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Settings</a><a
                            class="dropdown-item" href="#!">Themes</a>
                        <div class="dropdown-divider"></div><a class="dropdown-item text-danger" href="#!">Remove </a>
                    </div>
                </div>
            </div>
        </div>-->
        <${DragDropContext} onDragEnd=${onDragEnd} style="overflow:auto">
        <div class="waiting">
            
            ${[
                {id:'scheduled',label:"Rendez vous"},
                {id:'waiting',label:"Salle d'attente"},
                {id:'doing',label:'En cours'},
                {id:'done',label:'Traité'},
            ]
            .map(column=>{
                const visits = todayVisits.filter(it=>getCategory(it) == column.id);
                
                return html`
                <div class="kanban-column">
                    <${Header} label=${column.label} count=${visits.length} />
                    
                    <div class="kanban-items-container scrollbar" style="height:100%">
                   
                        <${Droppable} droppableId=${column.id} >
                        ${(provided:any, snapshot:any) => (
            html`<div
              ref=${provided.innerRef}
              style=${snapshot.isDraggingOver}
              ...${provided.droppableProps}
              style="height:100%"
            >
            ${
                visits.map(it=>html`<${Item} ...${it} />`)
            }
            ${provided.placeholder}
                            </div>`
        )}
                            </${Droppable}>
                            
                                <form class="add-card-form mt-3">
                                    <textarea class="form-control" data-input="add-card" rows="2"
                                        placeholder="Enter a title for this card..."></textarea>
                                    <div class="row gx-2 mt-2">
                                        <div class="col">
                                            <button class="btn btn-primary btn-sm btn-block" type="button">Add</button>
                                        </div>
                                        <div class="col">
                                            <button class="btn btn-outline-secondary btn-sm btn-block border-400"
                                                type="button" data-btn-form="hide">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                    </div>
                    <!--
                    <div class="kanban-column-footer">
                        <button class="btn btn-link btn-sm btn-block btn-add-card text-decoration-none text-600"
                            type="button"><span class="fas fa-plus mr-2"></span>Add another card</button>
                    </div>
        -->
                </div>`;})}
                
        </div></${DragDropContext}>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="kanban-modal-label-1" aria-hidden="true"
        id="kanban-modal-1">
        <div class="modal-dialog modal-lg mt-6" role="document">
            <div class="modal-content border-0">
                <div class="position-absolute top-0 right-0 mt-3 mr-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="bg-light rounded-top-lg py-3 pl-4 pr-6">
                        <h4 class="mb-1" id="kanban-modal-label-1">Add a new illustration to the landing page</h4>
                        <p class="fs--2 mb-0">Added by <a class="link-600 font-weight-semi-bold" href="#!">Antony</a>
                        </p>
                    </div>
                    <div class="p-4">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-user"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Reviewers</h5>
                                        <ul class="nav avatar-group mb-0">
                                            <li class="nav-item dropdown"></li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#"
                                                    role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/1.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/1.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Anna Karinina</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/2.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/2.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Antony Hopkins</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/3.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/3.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Rowan Atkinson</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/4.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/4.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">John Doe</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/5.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/5.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Emily Rose</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/6.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/6.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Marry Jane</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link dropdown-toggle dropdown-caret-none p-0 ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <div class="avatar-name rounded-circle avatar-button">
                                                            <span><span class="fas fa-plus"
                                                                    data-fa-trasnsform="grow-2"></span></span></div>
                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md">
                                                    <h6 class="dropdown-header py-0 px-3 mb-0">Select Member</h6>
                                                    <div class="dropdown-divider"></div>
                                                    <form class="px-3 mb-2">
                                                        <input class="form-control form-control-sm" type="text"
                                                            placeholder="Search team member" />
                                                    </form>
                                                    <ul class="list-unstyled">
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/1.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Anna Karinina</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/2.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Antony Hopkins</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/3.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Rowan Atkinson</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/4.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">John Doe</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/5.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Emily Rose</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/6.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Marry Jane</h6>
                                                                </div>
                                                            </a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Labels</h5>
                                        <div class="d-flex"><span
                                                class="badge mr-1 py-2 badge-soft-success">New</span><span
                                                class="badge mr-1 py-2 badge-soft-primary">Goal</span><span
                                                class="badge mr-1 py-2 badge-soft-info">Enhancement</span>
                                            <div class="dropdown dropright">
                                                <button
                                                    class="btn btn-sm btn-secondary px-2 fsp-75 bg-400 border-400 dropdown-toggle dropdown-caret-none"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span class="fas fa-plus"></span></button>
                                                <div class="dropdown-menu">
                                                    <h6 class="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="px-3">
                                                        <button class="badge-soft-success dropdown-item rounded mb-2"
                                                            type="button">New</button>
                                                        <button class="badge-soft-primary dropdown-item rounded mb-2"
                                                            type="button">Goal</button>
                                                        <button class="badge-soft-info dropdown-item rounded mb-2"
                                                            type="button">Enhancement</button>
                                                        <button class="badge-soft-danger dropdown-item rounded mb-2"
                                                            type="button">Bug</button>
                                                        <button class="badge-soft-secondary dropdown-item rounded mb-2"
                                                            type="button">Documentation</button>
                                                        <button class="badge-soft-warning dropdown-item rounded mb-2"
                                                            type="button">Helper</button>
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="px-3">
                                                        <button
                                                            class="btn btn-sm btn-block btn-outline-secondary border-400">Create
                                                            Label</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Description</h5>
                                        <p class="text-word-break fs--1">The illustration must match to the contrast of
                                            the
                                            theme. The illustraion must described the concept of the design. To know
                                            more
                                            about the theme visit the page. <a
                                                href="https://prium.github.io/falcon/v3.0.0-alpha10/index.html"
                                                target="_blank">https://prium.github.io/falcon/v3.0.0-alpha10/index.html</a>
                                        </p>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"> <span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-paperclip"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <div class="d-flex justify-content-between mb-2">
                                            <h5 class="mb-0 fs-0">Attachments</h5>
                                            <div class="dropdown">
                                                <button
                                                    class="btn btn-falcon-default btn-sm dropdown-toggle dropdown-caret-none fs--2"
                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"> <span class="fas fa-plus"></span></button>
                                                <div class="dropdown-menu dropdown-menu-right"><a class="dropdown-item"
                                                        href="#!">Computer</a><a class="dropdown-item" href="#!">Google
                                                        Drive</a>
                                                    <div class="dropdown-divider"></div><a class="dropdown-item"
                                                        href="#!">Attach Link</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="assets/img/kanban/3.jpg" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/img/kanban/3.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/img/kanban/3.jpg"
                                                        data-gallery="attachment-title">final-img.jpg</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-18 5:25 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="assets/img/kanban/4.jpg" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/img/kanban/4.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/img/kanban/4.jpg"
                                                        data-gallery="attachment-title">picture.png</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-20 4:34 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="#!">
                                                <div class="bg-attachment"><span
                                                        class="text-uppercase font-weight-bold">txt</span>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="#!">sample.txt</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-21 2:10 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="#!">
                                                <div class="bg-attachment"><span
                                                        class="text-uppercase font-weight-bold">pdf</span>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="#!">example.pdf</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-05-02 11:34 am</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center"><a class="text-decoration-none mr-3"
                                                href="assets/video/beach.mp4" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/video/beach.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                    <div class="icon-play"><span class="fas fa-play"></span></div>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/video/beach.mp4"
                                                        data-gallery="attachment-title">beach.mp4</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-05-10 3:40 pm</p>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary far fa-comment"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-3 fs-0">Comments</h5>
                                        <div class="d-flex">
                                            <div class="avatar avatar-l mr-2">
                                                <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                            </div>
                                            <div class="flex-1 fs--1">
                                                <div class="position-relative border rounded mb-3">
                                                    <form>
                                                        <textarea
                                                            class="form-control border-0 rounded-bottom-0 resize-none"
                                                            rows="3" contentEditable="true"></textarea>
                                                        <div
                                                            class="d-flex flex-between-center bg-light rounded-bottom p-2 mt-1">
                                                            <button class="btn btn-sm btn-primary"
                                                                type="submit">Save</button>
                                                            <ul class="list-inline mb-0">
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span
                                                                            class="fas fa-paperclip"></span></a>
                                                                </li>
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span class="fas fa-at"></span></a>
                                                                </li>
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span class="fas fa-image"></span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-3"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-1 bg-200 rounded-lg p-2"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Rowan </a>This time we should finish
                                                    the
                                                    task before the deadline</p><a href="#!">Like</a> &bull; <a
                                                    href="#!">Reply</a> &bull; 23min
                                            </div>
                                        </div>
                                        <div class="d-flex"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-1 bg-200 rounded-lg p-2"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Emma </a>We have more task to do</p><a
                                                    href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 2hour
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-list-ul"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-3 fs-0">Activity </h5>
                                        <div class="d-flex mb-3"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-0"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Rowan </a>Added the card</p>
                                                <div class="fs--2">6 hours ago</div>
                                            </div>
                                        </div>
                                        <div class="d-flex"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-0"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Anna </a>attached final-pic.png to
                                                    this
                                                    card</p>
                                                <div class="fs--2">4 hours ago</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mt-5 mt-lg-0">Add To Card</h6>
                                <ul class="nav flex-lg-column fs--1">
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-user mr-2"></span>Members</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-tag mr-2"></span>Label</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-paperclip mr-2"></span>Attachments</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-check mr-2"></span>Checklists</a></li>
                                </ul>
                                <h6 class="mt-3">Actions</h6>
                                <ul class="nav flex-lg-column fs--1">
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="far fa-copy mr-2"> </span>Copy</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-arrow-right mr-2"></span>Move</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!"><span class="fas fa-trash-alt mr-2"></span>Remove</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="kanban-modal-label-2" aria-hidden="true"
        id="kanban-modal-2">
        <div class="modal-dialog modal-lg mt-6" role="document">
            <div class="modal-content border-0">
                <div class="position-relative overflow-hidden py-8">
                    <div class="bg-holder rounded-top-lg" style="background-image:url(assets/img/kanban/1.jpg);">
                    </div>
                    <!--/.bg-holder-->

                </div>
                <div class="position-absolute top-0 right-0 mt-3 mr-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="bg-light rounded-top-lg py-3 px-4">
                        <h4 class="mb-1" id="kanban-modal-label-2">Add a new illustration to the landing page</h4>
                        <p class="fs--2 mb-0">Added by <a class="link-600 font-weight-semi-bold" href="#!">Antony</a>
                        </p>
                    </div>
                    <div class="p-4">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-user"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Reviewers</h5>
                                        <ul class="nav avatar-group mb-0">
                                            <li class="nav-item dropdown"></li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#"
                                                    role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/1.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/1.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Anna Karinina</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/2.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/2.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Antony Hopkins</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/3.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/3.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Rowan Atkinson</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/4.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/4.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">John Doe</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/5.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/5.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Emily Rose</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/6.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/6.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Marry Jane</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link dropdown-toggle dropdown-caret-none p-0 ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <div class="avatar-name rounded-circle avatar-button">
                                                            <span><span class="fas fa-plus"
                                                                    data-fa-trasnsform="grow-2"></span></span></div>
                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md">
                                                    <h6 class="dropdown-header py-0 px-3 mb-0">Select Member</h6>
                                                    <div class="dropdown-divider"></div>
                                                    <form class="px-3 mb-2">
                                                        <input class="form-control form-control-sm" type="text"
                                                            placeholder="Search team member" />
                                                    </form>
                                                    <ul class="list-unstyled">
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/1.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Anna Karinina</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/2.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Antony Hopkins</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/3.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Rowan Atkinson</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/4.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">John Doe</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/5.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Emily Rose</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/6.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Marry Jane</h6>
                                                                </div>
                                                            </a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Labels</h5>
                                        <div class="d-flex"><span
                                                class="badge mr-1 py-2 badge-soft-success">New</span><span
                                                class="badge mr-1 py-2 badge-soft-primary">Goal</span><span
                                                class="badge mr-1 py-2 badge-soft-info">Enhancement</span>
                                            <div class="dropdown dropright">
                                                <button
                                                    class="btn btn-sm btn-secondary px-2 fsp-75 bg-400 border-400 dropdown-toggle dropdown-caret-none"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span class="fas fa-plus"></span></button>
                                                <div class="dropdown-menu">
                                                    <h6 class="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="px-3">
                                                        <button class="badge-soft-success dropdown-item rounded mb-2"
                                                            type="button">New</button>
                                                        <button class="badge-soft-primary dropdown-item rounded mb-2"
                                                            type="button">Goal</button>
                                                        <button class="badge-soft-info dropdown-item rounded mb-2"
                                                            type="button">Enhancement</button>
                                                        <button class="badge-soft-danger dropdown-item rounded mb-2"
                                                            type="button">Bug</button>
                                                        <button class="badge-soft-secondary dropdown-item rounded mb-2"
                                                            type="button">Documentation</button>
                                                        <button class="badge-soft-warning dropdown-item rounded mb-2"
                                                            type="button">Helper</button>
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="px-3">
                                                        <button
                                                            class="btn btn-sm btn-block btn-outline-secondary border-400">Create
                                                            Label</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Description</h5>
                                        <p class="text-word-break fs--1">The illustration must match to the contrast of
                                            the
                                            theme. The illustraion must described the concept of the design. To know
                                            more
                                            about the theme visit the page. <a
                                                href="https://prium.github.io/falcon/v3.0.0-alpha10/index.html"
                                                target="_blank">https://prium.github.io/falcon/v3.0.0-alpha10/index.html</a>
                                        </p>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"> <span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-paperclip"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <div class="d-flex justify-content-between mb-2">
                                            <h5 class="mb-0 fs-0">Attachments</h5>
                                            <div class="dropdown">
                                                <button
                                                    class="btn btn-falcon-default btn-sm dropdown-toggle dropdown-caret-none fs--2"
                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"> <span class="fas fa-plus"></span></button>
                                                <div class="dropdown-menu dropdown-menu-right"><a class="dropdown-item"
                                                        href="#!">Computer</a><a class="dropdown-item" href="#!">Google
                                                        Drive</a>
                                                    <div class="dropdown-divider"></div><a class="dropdown-item"
                                                        href="#!">Attach Link</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="assets/img/kanban/3.jpg" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/img/kanban/3.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/img/kanban/3.jpg"
                                                        data-gallery="attachment-title">final-img.jpg</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-18 5:25 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="assets/img/kanban/4.jpg" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/img/kanban/4.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/img/kanban/4.jpg"
                                                        data-gallery="attachment-title">picture.png</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-20 4:34 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="#!">
                                                <div class="bg-attachment"><span
                                                        class="text-uppercase font-weight-bold">txt</span>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="#!">sample.txt</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-21 2:10 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="#!">
                                                <div class="bg-attachment"><span
                                                        class="text-uppercase font-weight-bold">pdf</span>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="#!">example.pdf</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-05-02 11:34 am</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center"><a class="text-decoration-none mr-3"
                                                href="assets/video/beach.mp4" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/video/beach.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                    <div class="icon-play"><span class="fas fa-play"></span></div>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/video/beach.mp4"
                                                        data-gallery="attachment-title">beach.mp4</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-05-10 3:40 pm</p>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary far fa-comment"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-3 fs-0">Comments</h5>
                                        <div class="d-flex">
                                            <div class="avatar avatar-l mr-2">
                                                <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                            </div>
                                            <div class="flex-1 fs--1">
                                                <div class="position-relative border rounded mb-3">
                                                    <form>
                                                        <textarea
                                                            class="form-control border-0 rounded-bottom-0 resize-none"
                                                            rows="3" contentEditable="true"></textarea>
                                                        <div
                                                            class="d-flex flex-between-center bg-light rounded-bottom p-2 mt-1">
                                                            <button class="btn btn-sm btn-primary"
                                                                type="submit">Save</button>
                                                            <ul class="list-inline mb-0">
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span
                                                                            class="fas fa-paperclip"></span></a>
                                                                </li>
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span class="fas fa-at"></span></a>
                                                                </li>
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span class="fas fa-image"></span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-3"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-1 bg-200 rounded-lg p-2"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Rowan </a>This time we should finish
                                                    the
                                                    task before the deadline</p><a href="#!">Like</a> &bull; <a
                                                    href="#!">Reply</a> &bull; 23min
                                            </div>
                                        </div>
                                        <div class="d-flex"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-1 bg-200 rounded-lg p-2"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Emma </a>We have more task to do</p><a
                                                    href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 2hour
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-list-ul"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-3 fs-0">Activity </h5>
                                        <div class="d-flex mb-3"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-0"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Rowan </a>Added the card</p>
                                                <div class="fs--2">6 hours ago</div>
                                            </div>
                                        </div>
                                        <div class="d-flex"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-0"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Anna </a>attached final-pic.png to
                                                    this
                                                    card</p>
                                                <div class="fs--2">4 hours ago</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mt-5 mt-lg-0">Add To Card</h6>
                                <ul class="nav flex-lg-column fs--1">
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-user mr-2"></span>Members</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-tag mr-2"></span>Label</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-paperclip mr-2"></span>Attachments</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-check mr-2"></span>Checklists</a></li>
                                </ul>
                                <h6 class="mt-3">Actions</h6>
                                <ul class="nav flex-lg-column fs--1">
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="far fa-copy mr-2"> </span>Copy</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-arrow-right mr-2"></span>Move</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!"><span class="fas fa-trash-alt mr-2"></span>Remove</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="kanban-modal-label-3" aria-hidden="true"
        id="kanban-modal-3">
        <div class="modal-dialog modal-lg mt-6" role="document">
            <div class="modal-content border-0">
                <div class="position-relative overflow-hidden py-8">
                    <div class="bg-holder rounded-top-lg" style="background-image:url(assets/img/kanban/2.jpg);">
                    </div>
                    <!--/.bg-holder-->

                </div>
                <div class="position-absolute top-0 right-0 mt-3 mr-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="bg-light rounded-top-lg py-3 px-4">
                        <h4 class="mb-1" id="kanban-modal-label-3">Add a new illustration to the landing page</h4>
                        <p class="fs--2 mb-0">Added by <a class="link-600 font-weight-semi-bold" href="#!">Antony</a>
                        </p>
                    </div>
                    <div class="p-4">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-user"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Reviewers</h5>
                                        <ul class="nav avatar-group mb-0">
                                            <li class="nav-item dropdown"></li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none" href="#"
                                                    role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/1.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/1.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Anna Karinina</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/2.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/2.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Antony Hopkins</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/3.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/3.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Rowan Atkinson</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/4.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/4.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">John Doe</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/5.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/5.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Emily Rose</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link p-0 dropdown-toggle dropdown-caret-none ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <img class="rounded-circle" src="assets/img/team/6.jpg"
                                                            alt="" />

                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md px-0 py-3">
                                                    <div class="d-flex align-items-center position-relative px-3">
                                                        <div class="avatar avatar-2xl mr-2">
                                                            <img class="rounded-circle" src="assets/img/team/6.jpg"
                                                                alt="" />

                                                        </div>
                                                        <div class="flex-1">
                                                            <h6 class="mb-0"><a class="stretched-link text-900"
                                                                    href="pages/profile.html">Marry Jane</a></h6>
                                                            <p class="mb-0 fs--2">Member</p>
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" /><a class="dropdown-item" href="#!">Member
                                                        Rule</a><a class="dropdown-item text-danger" href="#!">Remove
                                                        Member</a>
                                                </div>
                                            </li>
                                            <li class="nav-item dropdown"><a
                                                    class="nav-link dropdown-toggle dropdown-caret-none p-0 ml-n1"
                                                    href="#" role="button" data-toggle="dropdown">
                                                    <div class="avatar avatar-xl">
                                                        <div class="avatar-name rounded-circle avatar-button">
                                                            <span><span class="fas fa-plus"
                                                                    data-fa-trasnsform="grow-2"></span></span></div>
                                                    </div>
                                                </a>
                                                <div class="dropdown-menu dropdown-md">
                                                    <h6 class="dropdown-header py-0 px-3 mb-0">Select Member</h6>
                                                    <div class="dropdown-divider"></div>
                                                    <form class="px-3 mb-2">
                                                        <input class="form-control form-control-sm" type="text"
                                                            placeholder="Search team member" />
                                                    </form>
                                                    <ul class="list-unstyled">
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/1.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Anna Karinina</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/2.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Antony Hopkins</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/3.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Rowan Atkinson</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/4.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">John Doe</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/5.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Emily Rose</h6>
                                                                </div>
                                                            </a></li>
                                                        <li><a class="d-flex align-items-center text-decoration-none py-1 px-3"
                                                                href="pages/profile.html">
                                                                <div class="avatar avatar-xl mr-2">
                                                                    <img class="rounded-circle"
                                                                        src="assets/img/team/6.jpg" alt="" />

                                                                </div>
                                                                <div class="flex-1">
                                                                    <h6 class="mb-0 link-900">Marry Jane</h6>
                                                                </div>
                                                            </a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Labels</h5>
                                        <div class="d-flex"><span
                                                class="badge mr-1 py-2 badge-soft-success">New</span><span
                                                class="badge mr-1 py-2 badge-soft-primary">Goal</span><span
                                                class="badge mr-1 py-2 badge-soft-info">Enhancement</span>
                                            <div class="dropdown dropright">
                                                <button
                                                    class="btn btn-sm btn-secondary px-2 fsp-75 bg-400 border-400 dropdown-toggle dropdown-caret-none"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span class="fas fa-plus"></span></button>
                                                <div class="dropdown-menu">
                                                    <h6 class="dropdown-header py-0 px-3 mb-0">Select Label</h6>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="px-3">
                                                        <button class="badge-soft-success dropdown-item rounded mb-2"
                                                            type="button">New</button>
                                                        <button class="badge-soft-primary dropdown-item rounded mb-2"
                                                            type="button">Goal</button>
                                                        <button class="badge-soft-info dropdown-item rounded mb-2"
                                                            type="button">Enhancement</button>
                                                        <button class="badge-soft-danger dropdown-item rounded mb-2"
                                                            type="button">Bug</button>
                                                        <button class="badge-soft-secondary dropdown-item rounded mb-2"
                                                            type="button">Documentation</button>
                                                        <button class="badge-soft-warning dropdown-item rounded mb-2"
                                                            type="button">Helper</button>
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="px-3">
                                                        <button
                                                            class="btn btn-sm btn-block btn-outline-secondary border-400">Create
                                                            Label</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-2 fs-0">Description</h5>
                                        <p class="text-word-break fs--1">The illustration must match to the contrast of
                                            the
                                            theme. The illustraion must described the concept of the design. To know
                                            more
                                            about the theme visit the page. <a
                                                href="https://prium.github.io/falcon/v3.0.0-alpha10/index.html"
                                                target="_blank">https://prium.github.io/falcon/v3.0.0-alpha10/index.html</a>
                                        </p>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"> <span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-paperclip"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <div class="d-flex justify-content-between mb-2">
                                            <h5 class="mb-0 fs-0">Attachments</h5>
                                            <div class="dropdown">
                                                <button
                                                    class="btn btn-falcon-default btn-sm dropdown-toggle dropdown-caret-none fs--2"
                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"> <span class="fas fa-plus"></span></button>
                                                <div class="dropdown-menu dropdown-menu-right"><a class="dropdown-item"
                                                        href="#!">Computer</a><a class="dropdown-item" href="#!">Google
                                                        Drive</a>
                                                    <div class="dropdown-divider"></div><a class="dropdown-item"
                                                        href="#!">Attach Link</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="assets/img/kanban/3.jpg" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/img/kanban/3.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/img/kanban/3.jpg"
                                                        data-gallery="attachment-title">final-img.jpg</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-18 5:25 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="assets/img/kanban/4.jpg" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/img/kanban/4.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/img/kanban/4.jpg"
                                                        data-gallery="attachment-title">picture.png</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-20 4:34 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="#!">
                                                <div class="bg-attachment"><span
                                                        class="text-uppercase font-weight-bold">txt</span>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="#!">sample.txt</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-04-21 2:10 pm</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mb-3"><a class="text-decoration-none mr-3"
                                                href="#!">
                                                <div class="bg-attachment"><span
                                                        class="text-uppercase font-weight-bold">pdf</span>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="#!">example.pdf</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-05-02 11:34 am</p>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center"><a class="text-decoration-none mr-3"
                                                href="assets/video/beach.mp4" data-gallery="attachment-bg">
                                                <div class="bg-attachment">
                                                    <div class="bg-holder rounded"
                                                        style="background-image:url(assets/video/beach.jpg);">
                                                    </div>
                                                    <!--/.bg-holder-->

                                                    <div class="icon-play"><span class="fas fa-play"></span></div>
                                                </div>
                                            </a>
                                            <div class="flex-1 fs--2">
                                                <h6 class="mb-1"> <a class="text-decoration-none"
                                                        href="assets/video/beach.mp4"
                                                        data-gallery="attachment-title">beach.mp4</a>
                                                </h6><a class="link-600 font-weight-semi-bold" href="#!">Edit</a><span
                                                    class="mx-1">|</span><a class="link-600 font-weight-semi-bold"
                                                    href="#!">Remove</a>
                                                <p class="mb-0">Uploaded at 2020-05-10 3:40 pm</p>
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary far fa-comment"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-3 fs-0">Comments</h5>
                                        <div class="d-flex">
                                            <div class="avatar avatar-l mr-2">
                                                <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                            </div>
                                            <div class="flex-1 fs--1">
                                                <div class="position-relative border rounded mb-3">
                                                    <form>
                                                        <textarea
                                                            class="form-control border-0 rounded-bottom-0 resize-none"
                                                            rows="3" contentEditable="true"></textarea>
                                                        <div
                                                            class="d-flex flex-between-center bg-light rounded-bottom p-2 mt-1">
                                                            <button class="btn btn-sm btn-primary"
                                                                type="submit">Save</button>
                                                            <ul class="list-inline mb-0">
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span
                                                                            class="fas fa-paperclip"></span></a>
                                                                </li>
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span class="fas fa-at"></span></a>
                                                                </li>
                                                                <li class="list-inline-item mr-1"><a
                                                                        class="link-600 p-2 transition-base rounded"
                                                                        href="#!"><span class="fas fa-image"></span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-3"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-1 bg-200 rounded-lg p-2"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Rowan </a>This time we should finish
                                                    the
                                                    task before the deadline</p><a href="#!">Like</a> &bull; <a
                                                    href="#!">Reply</a> &bull; 23min
                                            </div>
                                        </div>
                                        <div class="d-flex"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-1 bg-200 rounded-lg p-2"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Emma </a>We have more task to do</p><a
                                                    href="#!">Like</a> &bull; <a href="#!">Reply</a> &bull; 2hour
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                    </div>
                                </div>
                                <div class="d-flex"><span class="fa-stack ml-n1 mr-3"><i
                                            class="fas fa-circle fa-stack-2x text-200"></i><i
                                            class="fa-inverse fa-stack-1x text-primary fas fa-list-ul"
                                            data-fa-transform="shrink-2"></i></span>
                                    <div class="flex-1">
                                        <h5 class="mb-3 fs-0">Activity </h5>
                                        <div class="d-flex mb-3"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/4.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-0"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Rowan </a>Added the card</p>
                                                <div class="fs--2">6 hours ago</div>
                                            </div>
                                        </div>
                                        <div class="d-flex"><a href="pages/profile.html">
                                                <div class="avatar avatar-l">
                                                    <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />

                                                </div>
                                            </a>
                                            <div class="flex-1 ml-2 fs--1">
                                                <p class="mb-0"><a class="font-weight-semi-bold"
                                                        href="pages/profile.html">Anna </a>attached final-pic.png to
                                                    this
                                                    card</p>
                                                <div class="fs--2">4 hours ago</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mt-5 mt-lg-0">Add To Card</h6>
                                <ul class="nav flex-lg-column fs--1">
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-user mr-2"></span>Members</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-tag mr-2"></span>Label</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-paperclip mr-2"></span>Attachments</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-check mr-2"></span>Checklists</a></li>
                                </ul>
                                <h6 class="mt-3">Actions</h6>
                                <ul class="nav flex-lg-column fs--1">
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="far fa-copy mr-2"> </span>Copy</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!">
                                            <span class="fas fa-arrow-right mr-2"></span>Move</a></li>
                                    <li class="nav-item mr-2 mr-lg-0"><a class="nav-link nav-link-card-details"
                                            href="#!"><span class="fas fa-trash-alt mr-2"></span>Remove</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
}

function time(timestamp?:number) {
    if(timestamp){
        const d = new Date(timestamp);
        return `${d.getHours().toString().padStart(2,'0')}:${d.getMinutes().toString().padStart(2,'0')}`;
    };
    return '';
}