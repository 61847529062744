


import { html } from 'htm/preact';
import { ObjectId } from 'mongodb';
import React, { PureComponent, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush,
} from 'recharts';
import { useSession } from './store';



function week(date: string) {
    const d = new Date(date);
    const onejan = new Date(d.getFullYear(), 0, 1);
    return d.getFullYear().toString() + '-' + Math.ceil((((d as any - (onejan as any)) / 86400000) + onejan.getDay() + 1) / 7).toString().padStart(2, '0');
}

export function Stats() {


    const [state, dispatch] = useSession();

    const [mode, setMode] = useState('week' as 'month' | 'day' | 'year' | 'week');

    const config = {
        year: { slice: 1, length: 4 },
        month: { slice: 2, length: 7 },
        day: { slice: 3, length: 10 },
        week: { slice: 3, length: 10 },
    }[mode];

    const map = state.billables
        .filter(it => it.date && it.date.indexOf('tofix') == -1)
        .reduce((p, billable) => {
            let key = billable.date.split('-').slice(0, config.slice).join('-');
            if (mode == 'week') {
                key = week(key).toString();
            }
            p[key] = p[key] || { price: 0, received: 0 };
            p[key].price += billable.price;
            p[key].received += billable.received;
            return p;
        }, {} as any);
    const data = Object.keys(map)
        .filter(key => mode == 'week' || key.length == config.length)
        .map(key => ({ name: key, ...map[key] }))
        .sort((a, b) => a.name < b.name ? -1 : 1);

    /*.map(grp => ({
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    }))*/

    const active = (name: string) => name == mode ? 'active' : '';

    return html`
    <div class="card mb-3" id="customersTable"
        data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
        <div class="card-header">
            <div class="row flex-between-center">
                <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                    <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Revenu</h5>

                </div>
                <div class="col-8 col-sm-auto text-right pl-2">
                <ul class="nav nav-pills stats-mode">
                    <li class="nav-item"><a onclick=${()=> setMode('year')} class="nav-link ${active('year')}">Annuel</a>
                    </li>
                    <li class="nav-item"><a onclick=${()=> setMode('month')} class="nav-link ${active('month')}">Mensuel</a>
                    </li>
                    <li class="nav-item"><a onclick=${()=> setMode('week')} class="nav-link
                            ${active('week')}">Hebdomadaire</a></li>
                    <li class="nav-item"><a onclick=${()=> setMode('day')} class="nav-link ${active('day')}">Journalier</a>
                    </li>
                </ul>
            </div>

            </div>
            
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                
                <br />
                <${BarChart} barGap=${2} width=${window.innerWidth - 210} height=${300} data=${data} margin=${{
                top: 20, right: 30, left: 20, bottom: 5,
            }}>
                    <${CartesianGrid} strokeDasharray="3 3" />
                    <${XAxis} dataKey="name" />
                    <${YAxis} />
                    <${Tooltip} />
                    <${Legend} />
                    ${data.length > 0 ?
                            html`
                    <${Brush} dataKey="name" height=${30} stroke="#8884d8" travellerWidth=${2} startIndex=${Math.max(0,
                        data.length - 30)} />` : ``
    
                        }
    
                    <${Bar} name="Tarif" dataKey="price" fill="#8884d8" />
                    <${Bar} name="Reçu" dataKey="received" fill="#82ca9d" />
                </${BarChart}>
            </div>
        </div>
    </div>

`;
}
