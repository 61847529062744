import { html } from "htm/preact";
import { generateId, getPage, Pagination, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";


import { debounce, getDownloadUrl, loadDrug, searchDrugs, searchDrugsDebounced, searchPatients, useSession } from "./store";
import { Patient, Prescription } from "./shared/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fetch from "node-fetch";
import { stat } from "fs";
import { route } from "preact-router";
import { toast } from "react-toastify";
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";





export function DrugsOverview(props: any) {

    const [state] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });
    const [drugs, setDrugs] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState(state.search);
    const [page, setPage] = useState(0);

    const [hospital, setHospital] = useState(false);

    let urlPage = getPage();

    if (state.search != search || urlPage != page) {
        if (state.search != search) {
            route(window.location.pathname);
            urlPage = 1;
            setPage(urlPage);
            setSearch(state.search);
            searchDrugs(state.search, urlPage, { hospital }).then(setDrugs);
        } else {
            setPage(urlPage);
            setSearch(state.search);
            searchDrugsDebounced(state.search, urlPage, { hospital }, setDrugs);
        }
    }



    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };
    /*
        useEffect(()=>{
            searchDrugs('',page).then(setDrugs);
        },[]);
    */


    return html`
    <style>
        .drugs .ma {
            width: 100px;
        }
        .drugs .price {
            min-width: 80px;
        }
        .drugs .actions {
            width: 60px;
        }
    </style>
    <div class="card mb-3 drugs">
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Médicaments</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">

                    <div class="form-check" style="display: unset;margin-right: 20px;padding: 0px;">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"  onclick=${(ev: any) => {
                            setHospital(ev.target.checked);
                            searchDrugs(state.search, page, { hospital: ev.target.checked }).then(setDrugs);
                        }}/>
                        <label class="form-check-label" for="flexCheckDefault">
                            Hopital ${hospital}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr class="d-flex">
                    
                        <th>
                            Visible
                        </th>
                        <th onclick=${onSort('no')}
                            class="align-middule sort ${sort.dir.no} ma" data-sort>
                            AMM</th>
                        <th onclick=${onSort('firstName')}
                            class="col sort align-middle ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="col-3 sort align-middle" >Generique</th>
                        <th onclick=${onSort('price')}
                            class="col-1 sort text-right ${sort.dir.price} price" data-sort="price">
                            Prix</th>
                        <th class="align-middle no-sort actions"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${drugs.data.map(DrugRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${drugs.count} page=${page} pageSize=${100}/>
</div>`;
}


export function DrugRow(drug: any) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])

    const [state, dispatch] = useSession();

    let visibleDrugs = (state.organizations[0] || {}).visibleDrugs || [] ;

    function onDeleteClick(patient: Patient) {
        dispatch({
            type: 'mutate',
            collection: 'patients',
            id: patient._id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger d-flex">

    <td class="align-middle actions">
    <span class="form-check form-switch">
            <input onclick=${(ev:any)=>{
                if(ev.target.checked) {
                    visibleDrugs = [...visibleDrugs, drug._id];
                }else {
                    visibleDrugs = [...visibleDrugs];
                    visibleDrugs.splice(visibleDrugs.indexOf(drug._id),1);
                }
                dispatch({type:'mutate', collection:'organizations', data: {visibleDrugs:[...visibleDrugs]} , id: state.user?.organizationId   });
            }} checked=${visibleDrugs.indexOf(drug._id)!== -1 } class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
        </span>
    </td>

    <td class="align-middle ma">${drug._id}</td>
    <td class="col text-break align-middle">
        <a class="bolder-black" href="/drugs/${drug._id}">
            <h6 style="margin-top:2px"> ${drug.fullname || drug.name}</h6>
        </a></td>
    <td class="col-3 text-break align-middle">${drug.generic_name}</td>
    <td class="col-1 text-right price">${drug.price?.toFixed(3)} DT</td>
    <td class="text-right actions">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any)=> {
                            ev.preventDefault();
                            ev.stopImmediatePropagation();
                setOpen(!open);
            }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h' ]} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white py-2">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${()=> onDeleteClick(drug)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}





export function PrescriptionForm({ patient }: { patient: Patient }) {

    const [drug, setDrug] = useState(null as any);
    const [quantity, setQuantity] = useInput(1);
    const [posology, setPosology] = useState(null as any);
    const [note, setNote] = useState('');
    const [lines, setLines] = useState([] as any[]);
    const [validated, setValidated] = useState(false);

    const today = new Date();
    const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [date, setDate] = useInput(todayDate);

    const [state, dispatch] = useSession();

    const drugsOptions = state.drugs.map(it=>({label:it.fullname || it.name , value: it._id}));
    const posologiesOptions:any[] = [];

    function onAddClick(ev:any){
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity() && drug) {
                setLines(lines.concat([{drug,posology,note}]));

                setValidated(false);
                setDrug(null);
                setNote('');
                setPosology(null);
        }
    }

    function doSetDrug(drug:any) {
        setDrug(drug);
        const lastUsedPosology = state.prescriptions
            .sort((a,b)=> a.date < b.date ? 1 : -1)
            .find(it=>it.lines.find(it=>it.drug?.value == drug.value))?.lines
            .find(it=>it.drug?.value == drug.value)?.posology;
        setPosology(lastUsedPosology);
    }

    const createPrescription = ()=>{
        if(lines.length) {

            const id = generateId(state.user?.organizationId || '');

            dispatch({
                type: 'mutate',
                collection: 'prescriptions',
                id,
                data: {
                    patientId: patient._id,
                    insurance: patient.insurance,
                    insuranceId: patient.insuranceId,
                    lines,
                    date,
                },
            });
            
            toast.success('Ok');
            route('/prescriptions/' + id);
        }

    };

    function handleCreate(value: any) {
        dispatch({
            type:'mutate',
            collection:'options',
            id: generateId(state.user?.organizationId||''),
            data: {type:'posology',value},
        });
        setPosology({label:value,value});
    };

    const allPosologiesOptions = posologiesOptions
    .concat((state.options)
    .filter(it=>it.type=='posology')
    .map(it=>({label:it.value,value:it.value})));


    return html`<div class="row">

    <div class="col-lg-6">
    <form onsubmit="${onAddClick}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
        
            <div class="col-lg-9">
                <label class="form-label" for="email3">Médiament</label>
                <${Select} options=${drugsOptions} value=${drug} onChange="${doSetDrug}"
                    className="rs form-control ${validated && (drug ? 'is-valid' : 'is-invalid')}" />
            </div>
            <div class="col-lg-3">
                <label class="form-label" for="last-name">Quantité</label>
                <input pattern=".{3,}" class="form-control" id="last-name" type="number" oninput="${setQuantity}" value=${quantity} />
            </div>
        
        <div>
            <label class="form-label" for="email3">Posologie</label>
            <${CreatableSelect} isClearable onCreateOption=${handleCreate} options=${allPosologiesOptions} value=${posology} onChange="${setPosology}"
                className="rs form-control ${validated && (posology ? 'is-valid' : 'is-invalid')}" />
        </div>
        <div>
            <label class="form-label" for="last-name">Note</label>
            <textarea pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${(ev:any)=>setNote(ev.target.value)}"
            value="${note}" />
        </div>
        <br/>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" type="submit">Ajouter la ligne</button>
        </div>
        </form>
    </div>
    <div class="col-lg-6" style="padding-left:30px">
    <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
            <input pattern=".{3,}" class="form-control" type="date" oninput="${setDate}"
            value="${date}" />
        </div>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">Nom de malade</label>
            <h5> ${patient?.firstName} ${patient?.lastName}</h5>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">${patient.insurance?.label}</label>
            <h5> ${patient?.insuranceId}</h5>
        </div>
        <div>
            <label class="form-label">Médicaments</label>
            ${lines.map(line=> html`<div>
                <div><h5> ${line.drug.label}</h5></div>
                <div>${line.posology?.label}</div>
                <div>${line.note}</div>
            </div>`) } 
        </div>
    </div>
</div>
    <div class="col-12 d-flex justify-content-end">
        <button onclick=${createPrescription} class="btn btn-primary" type="submit">Créer</button>
    </div>
    <!--<${DrugView} id=${drug?.value}/>-->
`;
}


export function AddPrescription({ id }: { id?: string }) {

    const [session,dispatch] = useSession();
    const patient = session.patients.find(it => it._id == id);

    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Ajouter Prescription</h5>
            </div>
            <div class="card-body bg-light">
                <${PrescriptionForm} patient=${patient} />
            </div>
        </div>
    </div>
</div>`;
}


export function PrescriptionView({ id }: { id:string }) {


    const [state,dispatch] = useSession();
    const prescription = state.prescriptions.find(it=>it._id == id);
    if(prescription) {
        const patient = state.patients.find(it=>it._id == prescription.patientId);


    function cancel(){
       if( confirm('Ete vous sure de vouloir annuler cette ordonnace?')) {
           dispatch({
               type:'mutate',
               id: prescription?._id,
               data:{deletedAt: Date.now()},
           })
       }
    }

    return html`
    <div class="col-lg-6" style="padding-left:30px">
    <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
            <input pattern=".{3,}" class="form-control" type="date" readonly
            value="${prescription.date}" />
        </div>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">Nom de malade</label>
            <h5> ${patient?.firstName} ${patient?.lastName}</h5>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">${patient?.insurance?.label}</label>
            <h5> ${patient?.insuranceId}</h5>
        </div>
        <div>
            <label class="form-label">Médicaments</label>
            ${prescription.lines.map(line=> html`<div>
                <div><h5> ${line.drug?.label}</h5></div>
                <div>${line.posology?.label}</div>
                <div>${line.note}</div>
            </div>`) } 
        </div>
    </div>
    <div class="col-12 d-flex justify-content-end">
    <button onclick=${cancel} class="btn btn-primary" type="submit">Annuler</button>
        <button onclick=${()=>print()} class="btn btn-primary" type="submit">Imprimer</button>
    </div>`;
    }else {
        return html`<div>NOT FOUND</div>`;
    }
}

export function DrugView({id}:{id:string}) {

    const [drug,setDrug] = useState(null as any);


    useEffect(()=>{
        if(id) {
            loadDrug(id).then(setDrug);
        }
    },[id]);

    if(drug) {
        drug.hospital = drug._id.endsWith('H');

        return html`<div class="row g-0">
        <div>
            <div class="card mb-3">
                <div class="card-header">
                    <h5 class="mb-0">${drug.fullname || drug.name}  
                    ${drug.hospital ? html`<${FontAwesomeIcon} icon=${['fa', 'hospital' ]}/>` : ''}</h5>
                    
                </div>
                <div class="card-body">

                        <table class="table fs--1 mt-3">
                          <tbody>
                            <tr>
                              <td class="bg-100" style="width: 30%;">Autorisation de mise sur le marché</td>
                              <td>N° <strong>${drug._id}</strong> le <strong>${drug.ma_date}</strong></td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">veic_class</td>
                              <td>${drug.veic_class}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">sub_class</td>
                              <td>${drug.sub_class} / ${drug.therapeutic_class}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">generic_name</td>
                              <td>${drug.generic_name}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">posology</td>
                              <td>${drug.posology}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">indication</td>
                              <td>${drug.indication}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">packaging</td>
                              <td>${drug.packaging}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">form</td>
                              <td>${drug.form}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">specification</td>
                              <td>${drug.specification}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">shelf_life</td>
                              <td>${drug.shelf_life}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">code</td>
                              <td>${drug.code}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">table</td>
                              <td>${drug.table}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">princeps</td>
                              <td>${drug.princeps}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">laboratory</td>
                              <td>${drug.laboratory} - ${drug.country}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">price</td>
                              <td>${drug.price} DT</td>
                            </tr>
                          </tbody>
                        </table>
                </div>
            </div>
        </div>
    </div>`

    }else {
        return html`<div>loading...</div>`;
    }

}

