import { html } from "htm/preact";
import { generateId, getPage, Pagination, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { debounce, getDownloadUrl, searchPatients, SessionContext, SFile, state, useSession } from "./store";
import { Billable, Patient } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ObjectId from "bson-objectid";
import * as storage from 'idb-keyval';
import CreatableSelect from 'react-select/creatable';



const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{label:'Selectioner'} ,{label:'CNSS',value:'cnss'},{label:'CNRPS',value:'cnrps'} ];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/

export function PatientForm({ patient }: { patient?: Patient }) {

    const [firstName, setFirstName] = useInput(patient?.firstName || '');
    const [lastName, setLastName] = useInput(patient?.lastName || '');
    const [birthdate, setBirthdate] = useInput(patient?.birthdate || '');
    const [gender, setGender] = useState(patient?.gender || '');
    const [validated, setValidated] = useState(false);
    const [city, setCity] = useState(patient?.city || {} as any);
    const [insurance, setInsurance] = useState(patient?.insurance);
    const [insuranceId,setInsuranceId] = useInput(patient?.insuranceId);
    const [phones,setPhones] = useState(patient?.phones || []);

    const [state, dispatch] = useSession();



    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            const id = patient?._id || generateId(state.user?.organizationId||'');

            const data:any = { firstName, lastName, birthdate, gender, phones, insurance, insuranceId,
                 city: { label: city.label, value: city.value } };

            if(!patient?._id) {
                data.no = (state.patients.sort((a,b)=>(a.no || 0) > (b.no || 0) ? -1 : 1)[0]?.no || 0)+1;
            }

            dispatch({
                type: 'mutate', collection: 'patients', id,
                data,
            });
            
            toast.success(patient?._id ? 'Patient mise à jour' : 'Patient ajouté');
            route('/patients/' + id);
            /* DB.put('Patient',
                 randomString(9),
                 {
                     firstName,
                     lastName,
                     birthdate,
                     gender,
                     city,
                 }); */
        }

    };


    function handleCreate(value: any) {
        dispatch({
            type:'mutate',
            collection:'options',
            id: generateId(state.user?.organizationId||''),
            data: {type:'insurance',value},
        });
        setInsurance({label:value,value});
    };

    const allSocialSecurityOptions = socialSecurityOptions
    .concat((state.options)
    .filter(it=>it.type=='insurance')
    .map(it=>({label:it.value,value:it.value})));

    return html`<form onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="first-name">Prénom</label>
        <input required pattern=".{3,}" class="form-control" id="first-name" type="text" oninput="${setFirstName}"
            value=${firstName} />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="last-name">Nom</label>
        <input required pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${setLastName}"
            value="${lastName}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email1">Date de naissance</label>
        <input class="form-control" id="email1" type="date" value="${birthdate}" oninput="${setBirthdate}" required />
        <div class="invalid-feedback">At least 8 characters</div>

    </div>
    <div class="col-lg-6">
        <div class="row">
            <label class="form-label">Sexe</label>
            <div class="row" style="padding-left:40px">
                <div onclick="${() => setGender('male')}" class="col form-check mb-0 lh-1">
                    <input class="form-check-input" type="radio" value="male" id="tag-everyone"
                        checked="${gender == 'male' ? 'checked' : ''}" name="tag-settings" required />
                    <label class="form-check-label mb-0" for="tag-everyone">Homme
                    </label>
                </div>
                <div onclick="${() => setGender('female')}" class="col form-check mb-0 lh-1">
                    <input class="form-check-input" type="radio" value="female" id="group-members"
                        checked="${gender == 'female' ? 'checked' : ''}" name="tag-settings" required />
                    <label class="form-check-label mb-0" for="group-members">Femme
                    </label>
                </div>
            </div>

        </div>
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="phones-id">Numéro de téléphone</label>
        <input pattern=".{3,}" class="form-control" id="phones-id" type="text" oninput="${(ev:any)=> setPhones(ev.target.value.split(';'))}"
            value="${phones?.join(';')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email3">Ville</label>
        <${Select} options=${options} value="${city}" onChange="${setCity}"
            className="rs form-control ${validated && (city ? 'is-valid' : 'is-invalid')}" />

    </div>
    <div class="col-lg-6">
        <label class="form-label" for="insurance">Carnet de soins</label>
        <${CreatableSelect} isClearable options=${allSocialSecurityOptions} value="${insurance}" 
            onChange="${setInsurance}" onCreateOption=${handleCreate} 
            className="rs form-control ${validated && (insurance ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="insurance-id">Numéro du carnet de soins</label>
        <input pattern=".{3,}" class="form-control" id="insurance-id" type="text" oninput="${setInsuranceId}"
            value="${insuranceId}" />
    </div>
    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"> ${patient?._id ? 'Update' : 'Créer'}</button>
    </div>
</form>`;
}


export function AddPatient() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Ajouter Patient</h5>
            </div>
            <div class="card-body bg-light">
                <${PatientForm} />
            </div>
        </div>
    </div>
</div>`;
}

export function UpdatePatient({ id }: { id: string }) {
    const [session] = useSession();
    const patient = session.patients.find(it => it._id == id);
    if (patient) {
        console.log('PATIENT111', id, session.patients.find(it => it._id == id))
        return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Update Patient</h5>
            </div>
            <div class="card-body bg-light">
                <${PatientForm} patient=${patient} />
            </div>
        </div>
    </div>
</div>`;
    } else {
        return html`<div>Loading</div>`;
    }
}


export function PatientsOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();


    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    const allPatients = searchPatients(session.patients,q,sort,dir,page);
    const patients = allPatients.slice((page - 1) * 20, page * 20);

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Patients</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/patients-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                            <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
-->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight-240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('birthdate')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.birthdate}" data-sort="joined">
                            Date de naissance</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${patients.map(PatientRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${allPatients.length} page=${page}/>
</div>`;
}


export function PatientRow(patient: Patient) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    },[])

    const [state,dispatch] = useSession();


    function onDeleteClick(patient:Patient) {
        dispatch({
            type: 'mutate',
            collection: 'patients',
            id: patient._id,
            data: {deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="no align-middle white-space-nowrap">${patient.no?.toString().padStart(5, '0')}</td>
    <td class="name align-middle white-space-nowrap"><a href="/patients/${patient._id}">
                    <h6>${patient.firstName} ${patient.lastName}</h6>
        </a></td>
<!--    <td class="email align-middle py-2">${patient.email}</td> -->
    <td class="phone align-middle white-space-nowrap">${(patient.phones || [])[0]}</td>
    <td class="address align-middle white-space-nowrap pl-5">${patient.address} ${patient.city?.label}</td>
    <td class="joined align-middle">${patient.birthdate}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev:any)=>{
                            ev.preventDefault();
                            ev.stopImmediatePropagation();
                setOpen(!open);
            }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h' ]} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${()=>onDeleteClick(patient)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}


export function UnpaidPatientRow(patient: Patient & {unpaid:number,lastVisitAt:string}) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    },[])

    const [state,dispatch] = useSession();

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="no align-middle white-space-nowrap py-2">${patient.no?.toString().padStart(5, '0')}</td>
    <td class="name align-middle white-space-nowrap py-2"><a href="/patients/${patient._id}">
            <div class="d-flex d-flex align-items-center">
                <!--<div class="avatar avatar-xl mr-2">
                    <div class="avatar-name rounded-circle"><span>OG</span></div>
                </div>-->
                <div class="flex-1">
                    <h5 class="mb-0 fs--1">${patient.firstName} ${patient.lastName}</h5>
                </div>
            </div>
        </a></td>
<!--    <td class="email align-middle py-2">${patient.email}</td> -->
    <td class="phone align-middle white-space-nowrap py-2">${(patient.phones || [])[0]}</td>
    <td class="address align-middle white-space-nowrap pl-5 py-2">${patient.address} ${patient.city?.label}</td>
    <td class="joined align-middle py-2">${patient.lastVisitAt}</td>
    <td class="joined align-middle py-2">${patient.unpaid}</td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function PatientDetails({ id }: { id?: string }) {

    const [session,dispatch] = useSession();
    const patient = session.patients.find(it => it._id == id);

    /*
    const saveNotes:any = useRef(debounce(function(notes:string){
        dispatch({type:'mutate',collection: 'patients',id, data:{notes} });
    },4000)).current;
*/
    const setNotes = (ev:any) => dispatch({type:'mutate',collection: 'patients',id, data:{notes: ev.target.value}, debounce:true });
    if (patient) {

        return html`<!--<div class="card mb-3">
    <div class="card-header">
        <div class="row">
            <div class="col">
                <h5 class="mb-2">${patient.firstName} ${patient.lastName} (<a
                        href="mailto:${patient.email}">${patient.email}</a>)</h5><a
                    class="btn btn-falcon-default btn-sm" href="#!"><span class="fas fa-plus fs--2 mr-1"></span>Add
                    note</a>
                <button class="btn btn-falcon-default btn-sm dropdown-toggle ml-2 dropdown-caret-none" type="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                        class="fas fa-ellipsis-h"></span></button>
                <div class="dropdown-menu"><a class="dropdown-item" href="#">Edit</a><a class="dropdown-item"
                        href="#">Report</a><a class="dropdown-item" href="#">Archive</a>
                    <div class="dropdown-divider"></div><a class="dropdown-item text-danger" href="#">Delete user</a>
                </div>
            </div>
            <div class="col-auto d-none d-sm-block">
                <h6 class="text-uppercase text-600">Patient<span class="fas fa-user ml-2"></span></h6>
            </div>
        </div>
    </div>
    <div class="card-body border-top">
        <div class="d-flex">
            <${FontAwesomeIcon} icon=${['fas', 'user' ]} class="text-success mr-2" />
            <div class="flex-1">
                <p class="mb-0"> Patient was created</p>
                <p class="fs--1 mb-0 text-600">${new Date(patient.timestamps._id).toDateString()} </p>
            </div>
        </div>
    </div>
</div>-->
<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${patient.no?.toString().padStart(5, '0')}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/patients/${id}/prescribe">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt' ]} /> Prescrire un traitement</a></div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/patients/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt' ]} /> Mettre à jour les détails</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Prénom</p>
                    </div>
                    <div class="col">${patient.firstName}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Nom</p>
                    </div>
                    <div class="col">${patient.lastName}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Né(e) le</p>
                    </div>
                    <div class="col">${patient.birthdate}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Téléphone</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas de téléphone" value=${patient.phones?.join(';')} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-0">Addresse</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas d'addresse" value=${patient.address || patient.city?.label} />
                    </div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <label style="width:100%" class="form-label" for="notes">Observations</label>
                <textarea class="form-control" id="notes" rows="4" value=${patient.notes} oninput="${setNotes}"></textarea>
            </div>
        </div>
    </div>

</div>
<${BillablesOverview} patient=${patient} />

<${Drive} patientId=${patient._id} />
<!--
<div class="card mb-3">
    <div class="card-header">
        <h5 class="mb-0">Logs</h5>
    </div>
    <div class="card-body border-top p-0">
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoiceitems</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/23 15:29:45</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-warning rounded-pill">400</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoiceitems</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/19 21:32:12</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/26 12:23:43</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/12 23:32:12</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-danger rounded-pill">404</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/08 02:20:23</p>
            </div>
        </div>
        <div class="row g-0 align-items-center border-bottom py-2 px-3">
            <div class="col-md-auto pr-3"><span class="badge badge-soft-success rounded-pill">200</span></div>
            <div class="col-md mt-1 mt-md-0"><code>POST /v1/invoices/in_1Dnkhadfk</code></div>
            <div class="col-md-auto">
                <p class="mb-0">2019/02/01 12:29:34</p>
            </div>
        </div>
    </div>
    <div class="card-footer bg-light p-0"><a class="btn btn-link btn-block" href="#!">View more logs<span
                class="fas fa-chevron-right fs--2 ml-1"></span></a></div>
</div>
                -->
`;

    } else {
        return html`<div>Patient ${id} not found</div>`;
    }
}


export function BillablesOverview({ patient }: any) {

    const [state, dispatch] = useSession();

    const addBillable = () => {
        const today = new Date();
        const date = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        dispatch({
            type: 'mutate',
            collection: 'billables',
            id: generateId(state.user?.organizationId||''),
            data: { patientId: patient._id, date, price: 0, received: 0, description: '' },
        });
    };

    const billables = state.billables.filter((it: any) => it && !it.deletedAt && it.patientId ==patient._id);

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Visites</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onclick=${addBillable} class="btn btn-falcon-default btn-sm" type="button">
                        <${FontAwesomeIcon} icon=${['fas', 'plus' ]} />
                        <span class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                        <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
                        -->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th width="120px" class="sort" data-sort="date">Date</th>
                        <th class="sort align-middle" data-sort="name">Label</th>
                        <th width="100px" class="sort align-middle" data-sort="price">Tarif</th>
                        <th width="100px" class="sort align-middle" data-sort="received">Recu</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${billables.map((billable: any) => BillableRow(billable))}

                    <tr class="btn-reveal-trigger">
    <td class="align-middle">
    </td>
    <td class="align-middle py-2">
    </td>
    <td class="align-middle py-2">
    <strong> ${billables.reduce((p,n)=>p+n.price,0)} DT </strong>
    </td>
    <td class="align-middle py-2">
    <strong> ${billables.reduce((p,n)=>p+n.received,0)} DT</strong> 
    </td>
    <td class="align-middle white-space-nowrap py-2 text-right">
    Reste: <strong> ${billables.reduce((p,n)=>n.price,0) -billables.reduce((p,n)=>n.received,0) }  DT</strong>
    </td>

</tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-center">
        <button class="btn btn-sm btn-falcon-default mr-1" type="button" title="Previous"
            data-list-pagination="prev"><span class="fas fa-chevron-left"></span></button>
        <ul class="pagination mb-0"></ul>
        <button class="btn btn-sm btn-falcon-default ml-1" type="button" title="Next" data-list-pagination="next"><span
                class="fas fa-chevron-right"></span></button>
    </div>
</div>`;
}



export function BillableRow(billable: Billable) {

    const [state,dispatch] = useSession();

    const update:any = useRef(debounce(function(data:any){
        dispatch({type:'mutate', collection: 'billables', data , id: billable._id, debounce:true});
    },3000)).current;

    const [data,setData] = useState(billable);

    const handler = (field:string) => {
        return (ev:any)=> {
            const newData = {...data,[field]:ev.target.value};
            setData(newData);
            newData.price = parseFloat(newData.price.toString());
            newData.received = parseFloat(newData.received.toString());
            if(!isNaN(newData.price) && !isNaN(newData.received)) {
                update(newData);
            }
        };
    };

    
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    },[])


    function onDeleteClick() {
        dispatch({
            type: 'mutate',
            collection: 'billables',
            id: billable._id,
            data: {deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="align-middle">
        <input width="120px" class="form-control-plaintext" type="date" value=${data.date} oninput=${handler('date')} />
    </td>
    <td class="align-middle py-2">
        <input value=${data.description} oninput=${handler('description')} class="form-control-plaintext" type="text" />
    </td>
    <td class="align-middle py-2">
        <input value=${data.price} oninput=${handler('price')} class="form-control-plaintext" type="number" />
    </td>
    <td class="align-middle py-2">
        <input value=${data.received} oninput=${handler('received')} class="form-control-plaintext" type="number" />
    </td>
    <td class="align-middle white-space-nowrap py-2 text-right">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev:any)=>{
                            ev.preventDefault();
                            ev.stopImmediatePropagation();
                setOpen(!open);
            }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h' ]} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white py-2">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${()=>onDeleteClick()}>Delete</a></div>
            </div>
        </div>
    </td>

</tr>`;
}



export function UnpaidPatientsOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;

    const unpaidMap = session.billables.reduce((p,n)=>{
        const it = p[n.patientId] || {unpaid:0,lastVisitAt:'0'};
        p[n.patientId] = it;
        it.unpaid += n.price-n.received;
        it.lastVisitAt = (it.lastVisitAt < n.date && n.date.length == 10 ) ? n.date : it.lastVisitAt;
        return p;
    },{} as any);

    const page = getPage();

    const unpaidPatients = Object.keys(unpaidMap)
        .filter(key=>unpaidMap[key].unpaid )
        .map(key=>({...session.patients.find(it=>it._id==key),_id:key, ...unpaidMap[key] }))

    const allPatients = searchPatients(unpaidPatients as any ,q,sort,dir,page);
    const patients = allPatients.slice((page - 1) * 20, page * 20);

    const positiveTotal = unpaidPatients.filter(it=>it.unpaid>0).reduce((p,n)=>p+n.unpaid,0 );
    const negativeTotal = unpaidPatients.filter(it=>it.unpaid<0).reduce((p,n)=>p+n.unpaid,0 );

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Non payées</h5>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('lastVisitAt')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.lastVisitAt}" data-sort="joined">
                            Dernière visite</th>
                        <th onclick=${onSort('unpaid')} class="sort align-middle white-space-nowrap ${sort.dir.unpaid}"
                        data-sort="unpaid">Reste</th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${patients.map(UnpaidPatientRow as any)}
                </tbody>
            </table>
            <div style="text-align:right;padding-right:20px"><br/>
        <strong>Total Positive: </strong> ${positiveTotal} DT;
        <strong> Total Negative: </strong> ${negativeTotal}  DT
            </div>
        </div>
    </div>
    <${Pagination} count=${allPatients.length} page=${page}/>
</div>`;
}



export function Drive({patientId}:{patientId?:string}){


    const [state,dispatch] = useSession();
    const [files,doSetFiles] = useState([] as File[]);

    const [thumbs,setThumbs] = useState({} as any);

    let driveFiles:SFile[] = [];

    if(patientId) {
        driveFiles = state.files.filter(it=>it.patientId == patientId);
    }


    function setFiles(files:File[]) {
        for(const file of files) {
            const reader = new FileReader();
            reader.onload =(ev)=>{

                const id = generateId(state.user?.organizationId||'');



                const store = storage.createStore('storage','files');
                storage.set(id, {name:file.name,type:file.type,size:file.size,data:ev.target?.result},
                    store,
                );

                const formData = new FormData();
formData.append('file',file,id);
const options = { 
    method: 'POST', 
    body: formData , 
    // Uncomment to make it fail
    headers: { "Authorization": 'Bearer '+ state.token }
};
fetch('http://localhost:3000/api/v1/storage/upload', options)
.then(res=>{
    if(res.ok) {
        dispatch({
            type: 'mutate', collection: 'files', id,
            data: {uploaded: true },
        });

    }else {
        console.error(res);
    }
});

                dispatch({
                    type: 'mutate', collection: 'files', id,
                    data: {name:file.name,type:file.type,size:file.size , patientId },
                });
            };
            reader.readAsArrayBuffer(file);
        }
    }

    function onFileClick(file:SFile) {
        if(file.uploaded) {
            getDownloadUrl(state.token || '', file._id, file.name )
            .then(href=>{
                const link = document.createElement("a");
                link.href = href;
                link.download = file.name;
                alert(link.download);

                link.click();
            });
        } else {
            const store = storage.createStore('storage','files');

            storage.get(file._id,store)
            .then(fileData=>{
                const blob = new Blob([fileData.data], {type: file.type});
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.click();
            });
        }
    }

    useEffect(()=>{
        const toAdd = driveFiles.filter(it=>!thumbs[it._id]);
        if(toAdd.length) {
        const store = storage.createStore('storage','files');
        storage.getMany(toAdd.map(it=>it._id),store)
        .then(fileDatas=>{
            setThumbs(fileDatas.reduce((p,n,i)=>{
                if(n) {
                    p[toAdd[i]._id] = window.URL.createObjectURL(new Blob([n.data], {type: n.type}));
                }
                return p;
            },{...thumbs}));
        });
        }
    },[driveFiles.length]);

    return html`<div class="card mb-3">
              <div class="card h-lg-100">
                <div class="card-header d-flex flex-between-center bg-light py-2">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Fichiers</h5>
                  <div class="form-file">
        <input onchange=${(ev:any)=> setFiles(ev.target.files)}  class="form-file-input" id="customFile" type="file" />
        <label class="form-file-label" for="customFile">
            <span class="form-file-text">Choose file...</span>
            <span class="form-file-button">Browse</span>
        </label>
  </div>
                </div>
                <div class="card-body pb-0">
                    ${driveFiles.map(file=> html`<div class="d-flex mb-3 hover-actions-trigger align-items-center">
                    <div class="file-thumbnail">
                        <img class="border h-100 w-100 fit-cover rounded-lg" src=${thumbs[file._id]} alt="" />
                    </div>
                    <div class="ml-3 flex-shrink-1 flex-grow-1">
                      <h6 class="mb-1">
                      <a href="/files/${file._id}" class="stretched-link text-900 font-weight-semi-bold">${file.name}</a></h6>
                      <div class="fs--1"><span class="font-weight-semi-bold"></span><span class="font-weight-medium text-600 ml-2">${new Date(file.timestamps?._id).toLocaleString()}</span></div>
                      <div class="hover-actions right-0 top-50 translate-middle-y">
                      <a onclick=${()=>onFileClick(file)} class="btn btn-light border-300 btn-sm mr-1 text-600" data-toggle="tooltip" data-placement="top" title="Download" download="download">
                        <img src="assets/img/icons/cloud-download.svg" alt="" width="15" /></a>
                        <button class="btn btn-light border-300 btn-sm mr-1 text-600 shadow-none" type="button" data-toggle="tooltip" data-placement="top" title="Edit"><img src="assets/img/icons/edit-alt.svg" alt="" width="15" /></button>
                      </div>
                    </div>
                  </div>
                  <hr class="bg-200" />`) }
                </div>
              </div>
            </div>`;
}


export function FileViewer(file:SFile){

    const [url,setUrl] = useState('');

    useEffect(()=>{
        const store = storage.createStore('storage','files');
        storage.get(file._id,store)
        .then(fileData=>{
            setUrl(window.URL.createObjectURL(new Blob([fileData.data], {type: fileData.type})));
        });
    },[]);

    if(file.type.indexOf('image') !== -1) {
        return html`<img src=${url} alt=${file.name}/>`;
    } else {
        return html`<div>loading</div>`;
    }
}

export function FileDetails({id}:any){
    const [state,dispatch] = useSession();

    return FileViewer(state.files.find(it=>it._id == id) || {} as any);
}