import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { html } from 'htm/preact';
import { useState } from "preact/hooks";
import { to32 } from './shared/core';


export function useInput(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState(ev.target.value);
        if (callback) callback(ev.target.value);
    }];
}

/*
export function useForm(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState({...state, ...data);
        if (callback) callback(ev.target.value);
    }];
}*/


const longToByteArray = function (long: number) {
    // we want to represent the input as a 8-bytes array
    var byteArray = [0, 0, 0, 0, 0, 0];

    for (var index = 0; index < byteArray.length; index++) {
        var byte = long & 0xff;
        byteArray[index] = byte;
        long = (long - byte) / 256;
    }

    return Uint8Array.from(byteArray.reverse());
};

export function generateId(organizationId: string) {
    const t = longToByteArray(Date.now());
    const ta = to32(t);
    const time = ta.slice(1);
    return organizationId + time + randomString32(2).slice(0, 2);
}

export function randomString32(length: number) {
    const array = new Uint8Array(length);
    crypto.getRandomValues(array);
    return to32(array);
}



export function Pagination({ count, page, pageSize }: { count: number, page: number, pageSize?: number }) {
    pageSize = pageSize || 20;
    const pagesCount = Math.ceil(count / pageSize);
    return html`<div class="card-footer d-flex align-items-center justify-content-center">
    <a href="${window.location.pathname}?page=${page - 1}"
        class="btn btn-sm btn-falcon-default mr-1 ${page <= 1 ? 'disabled' : ''}" type="button" title="Previous"
        data-list-pagination="prev">
        <${FontAwesomeIcon} icon=${['fas', 'chevron-left']} /></a>
    <ul class="pagination mb-0" style="width:150px;">
        <span style="margin:auto">
            ${page}/${pagesCount} (${count})
        </span>
    </ul>
    <a href="${window.location.pathname}?page=${page + 1}"
        class="btn btn-sm btn-falcon-default ml-1 ${page >= pagesCount ? 'disabled' : ''}" type="button" title="Next"
        data-list-pagination="next">
        <${FontAwesomeIcon} icon=${['fas', 'chevron-right']} />
    </a>
</div>`;
}

export function getPage() {
    return parseInt(new URLSearchParams(location.search).get('page') || '1');

}