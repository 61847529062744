import { html } from "htm/preact";
import { generateId, getPage, Pagination, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import { createPurchaseOrder, debounce, getDownloadUrl, searchPatients, Api, SessionContext, SFile, state, useSession } from "./store";
import { Billable, Patient, PurchaseOrder, PurchaseOrderItem } from "./shared/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { route } from "preact-router";


export function PurchaseOrdersOverview(props: any) {

    const [state,dispatch] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });
    const [purchaseOrders, setPurchaseOrders] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState(state.search);
    const [page, setPage] = useState(0);

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    let urlPage = getPage();


    const q = state.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;

    if (state.search != search || urlPage != page) {
        if (state.search != search) {
            route(window.location.pathname);
            urlPage = 1;
            setPage(urlPage);
            setSearch(state.search);
            Api.search('/purchase-orders', state.search, urlPage, {}, state.token).then(setPurchaseOrders);
        } else {
            setPage(urlPage);
            setSearch(state.search);
            Api.searchDebounced('/purchase-orders',state.search, urlPage, {}, state.token, setPurchaseOrders);
        }
    }


    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Bons de commande</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/inventory/purchase-orders/new" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                            <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
-->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight-240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Date</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Crée par</th>
                            <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Recu par</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap" >Nombre de produits</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5" data-sort="address">Status</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${purchaseOrders.data.map(PurchaseOrderRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${purchaseOrders.count} page=${page} pageSize=${100}/>
</div>`;
}


export function PurchaseOrderRow(purchaseOrder: PurchaseOrder) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    },[])

    const [state,dispatch] = useSession();


    function onDeleteClick(purchaseOrder:PurchaseOrder) {
        dispatch({
            type: 'mutate',
            collection: 'purchaseOrders',
            id: purchaseOrder._id,
            data: {deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="no align-middle white-space-nowrap">
        <a href="/inventory/purchase-orders/${purchaseOrder._id}">${purchaseOrder.no}</a>
    </td>
    <td class="date align-middle white-space-nowrap">${new Date(purchaseOrder.createdAt).toDateString()}</td>
    <td class="name align-middle white-space-nowrap">
        ${purchaseOrder.createdByName}
        </td>
    <td class="phone align-middle white-space-nowrap">${purchaseOrder.receivedBy}</td>
    <td class="address align-middle white-space-nowrap pl-5">${purchaseOrder.items?.length}</td>
    <td class="address align-middle white-space-nowrap pl-5">${purchaseOrder.status}</td>

    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev:any)=>{
                            ev.preventDefault();
                            ev.stopImmediatePropagation();
                setOpen(!open);
            }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h' ]} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${()=>onDeleteClick(purchaseOrder)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}


export function PurchaseOrderItemRow(purchaseOrderItem: PurchaseOrderItem,status:string,updateItem?:any) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    },[])

    const [state,dispatch] = useSession();

    const drug = state.drugs.find(it=>it._id == purchaseOrderItem.ref);


    function onDeleteClick(purchaseOrderItem:PurchaseOrderItem) {
        dispatch(state => {
            state.newPurchaseOrder.items = state.newPurchaseOrder.items.filter(it=>it.ref!==purchaseOrderItem.ref);
            return 'newPurchaseOrder';
        });
    };

    function setQuantity(purchaseOrderItem:PurchaseOrderItem,value:string) {
        dispatch(state => {
            purchaseOrderItem.requestedQuantity = parseFloat(value);
            return 'newPurchaseOrder';
        });
    };

    function setDeliveredQuantity(purchaseOrderItem:PurchaseOrderItem,value:string) {
        purchaseOrderItem.deliveredQuantity = parseFloat(value);
        updateItem(purchaseOrderItem);
    };

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="col no align-middle">${drug?.fullname || drug?.name}</td>
    <td class="col name align-middle"><a href="/drugs/${purchaseOrderItem.ref}">
        ${drug?.form}
        </a></td>
    <td class="col phone align-middle">${status == 'creating'
        ?  html`<input onchange=${(ev:any)=>setQuantity(purchaseOrderItem, ev.target.value)}
            value=${purchaseOrderItem.requestedQuantity} class="form-control" type="number" required />`
        : purchaseOrderItem.requestedQuantity
        }</td>
        <td class="col address align-middle">
    ${status == 'receiving'
        ? html`<input onchange=${(ev:any)=>setDeliveredQuantity(purchaseOrderItem, ev.target.value)}
            value=${purchaseOrderItem.deliveredQuantity} class="form-control" type="number" required />`
        : purchaseOrderItem.deliveredQuantity}
        </td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        ${status == 'receiving'
        ? html`<button onclick=${()=>setDeliveredQuantity(purchaseOrderItem,purchaseOrderItem.requestedQuantity.toString())}>
        Tout</button>`
        : html`<div class="dropdown font-sans-serif">
            <button onclick=${(ev:any)=>{
                            ev.preventDefault();
                            ev.stopImmediatePropagation();
                setOpen(!open);
            }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h' ]} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${()=>onDeleteClick(purchaseOrderItem)}>Delete</a></div>
            </div>
        </div>`
        }
    </td>
</tr>`;
}



export function PurchaseOrderDetails({id}: {id:string}) {

    const [state,dispatch] = useSession();
    const [purchaseOrder,setPurchaseOrder] = useState(null as any);

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    function setAsDelivered(){
        Api.postDo('/purchase-orders', purchaseOrder._id, 'items-received', {
            items: purchaseOrder.items.map((it:any)=>({ref:it.ref,deliveredQuantity:it.deliveredQuantity})),
        }, state.token || '');
    }

    function updateItem(item:any) {
        const index = purchaseOrder.items.findIndex((it:any)=>it.ref == item.ref);
        purchaseOrder.items[index] = item;
        setPurchaseOrder({...purchaseOrder});
    }

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };
    useEffect(()=>{
        Api.get('/purchase-orders', id,state.token || '').then(setPurchaseOrder);
    },[id]);


    function buttons(){
        if(purchaseOrder.itemsReceivedAt) {
            return '';
        }else {
            return html`<a onclick=${setAsDelivered} class="btn btn-falcon-default btn-sm" type="button"><span
            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
            class="d-none d-sm-inline-block ml-1">J'ai reçu les produits</span></a>`;
        }
    }

    if(purchaseOrder) {

        const status = purchaseOrder.itemsReceivedAt ? 'received' : 'receiving';

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Bon de commande N° ${purchaseOrder?.no}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    ${buttons()}                    
                            <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
-->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight-240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="col no align-middle sort ${sort.dir.no}" onclick=${onSort('no')} data-sort>
                            Designation du medicament ou du produit pharmaceutique</th>
                        <th onclick=${onSort('firstName')}
                            class="col sort align-middle ${sort.dir.firstName}" data-sort>
                            Form our présentation</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="col sort align-middle" >Quantité demandée</th>
                        <th class="col sort align-middle" data-sort="address">Quantité livrée</th>
                        <th class="col align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${purchaseOrder?.items.map((it:any)=>PurchaseOrderItemRow(it,status,updateItem))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
</div>`;
    }else {
        return html`loading`;
    }
}


export function AddPurchaseOrder() {


    const [state,dispatch] = useSession();


    function onSaveClick(){
        createPurchaseOrder(state.token || '', state.newPurchaseOrder,dispatch);
    }
/*
    let purchaseOrder = state.purchaseOrders.find(it=>it._id == id);
    if(!purchaseOrder) {
        if(id == 'new') {
            // create new data
            const today = new Date();
            purchaseOrder = {
                date: `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`,
                items:[] as any[]} as any;
            dispatch({
                type: 'mutate',
                collection: 'purchaseOrders',
                id,
                data:purchaseOrder,
            });
            console.log('PURCHASEORDER',purchaseOrder);
            state.purchaseOrders.push(purchaseOrder as any);
        }else {
            return html`Not found`;
        }
    }
*/
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();


    const q = state.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
  //  const allPurchaseOrders = searchPurchaseOrders(state.purchaseOrders,q,sort,dir,page);
  //  const purchaseOrders = allPurchaseOrders.slice((page - 1) * 20, page * 20);


    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="row col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0" style="padding-right:10px">Nouvelle Bon de commande</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onclick=${onSaveClick} class="btn btn-falcon-default btn-sm ${state.busy?'disabled':''}" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Créer</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight-240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="col no align-middle sort ${sort.dir.no}" onclick=${onSort('no')} data-sort>
                            Designation du medicament ou du produit pharmaceutique</th>
                        <th onclick=${onSort('firstName')}
                            class="col sort align-middle ${sort.dir.firstName}" data-sort>
                            Form our présentation</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="col sort align-middle" >Quantité demandée</th>
                       <!-- <th class="col sort align-middle" data-sort="address">Quantité livrée</th> -->
                        <th class="col align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${state.newPurchaseOrder.items.map(it=>PurchaseOrderItemRow(it,''))}
                    <br/>
                    <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${[].length} page=${page}/>
</div>`;
}
